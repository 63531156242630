import { IconType } from 'react-icons';
import {
  FiChevronRight,
  FiMap,
  FiPrinter,
  FiShuffle,
  FiXCircle,
} from 'react-icons/fi';
import { TbViewportNarrow } from 'react-icons/tb';

import { AS_NODE_TYPES, AsNodeTypes } from './types';

const nodeIcons: Record<string, IconType> = {
  [AS_NODE_TYPES.MAP]: FiMap,
  [AS_NODE_TYPES.SUBFLOW]: FiChevronRight,
  [AS_NODE_TYPES.CONDITIONAL]: FiShuffle,
  'column-filter': TbViewportNarrow,
  'print-node': FiPrinter,
};

export function getNodeIcon(type: AsNodeTypes, subtype?: string): IconType {
  let key: string = type;
  if (type === AS_NODE_TYPES.PYTHON_NODE && subtype) {
    key = subtype;
  }

  const Icon = nodeIcons[key];

  return Icon ?? FiXCircle;
}
