import { Edge, Position } from '@xyflow/react';
import dagre, { GraphLabel } from 'dagre';
import _ from 'lodash';

import { JobNode } from '../job/Job';

/**
 * Layout react-flow elements using dagre
 * @param nodes - the internal representation of the nodes
 * @param edges
 * @param layout
 * @return {elements, layout} - layout after arranging the elements, contains height and width of graph for example
 */
export function getLayoutedElements(
  nodes: JobNode[],
  edges: Edge[],
  layout: GraphLabel
): { nodes: JobNode[]; layout: GraphLabel } {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph(layout);
  const isHorizontal = layout.rankdir === 'LR';

  const nodesMap = _.keyBy(nodes, 'id');

  // Recreate the nodes and edges in the dagreGraph
  nodes.forEach((el) => {
    dagreGraph.setNode(el.id, {
      width: nodesMap[el.id].measured?.width,
      height: nodesMap[el.id].measured?.height,
    });
  });
  edges.forEach((el) => {
    dagreGraph.setEdge(el.source, el.target);
  });

  // Do the layout
  dagre.layout(dagreGraph);

  // Modify and return the elements using the layoutet dagreGraph
  return {
    nodes: nodes.map((el) => {
      const nodeWithPosition = dagreGraph.node(el.id);

      return {
        ...el,
        targetPosition: isHorizontal ? Position.Left : Position.Top,
        sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        position: {
          x: nodeWithPosition.x - nodesMap[el.id].measured?.width / 2,
          y: nodeWithPosition.y - nodesMap[el.id].measured?.height / 2,
        },
      };
    }),
    layout: dagreGraph.graph(),
  };
}

/**
 * Layout react-flow elements using dagre, but without relying on the nodes array, which is only used for
 * width and height, which are only available after the nodes have been rendered once? (they need to be measured)
 * @param nodes
 * @param edges
 * @param width
 * @param height
 * @param layout
 * @return {elements, layout} - layout after arranging the elements, contains height and width of graph for example
 */
export function getLayoutedNodesWithoutMeasuring(
  nodes: JobNode[],
  edges: Edge[],
  width: number,
  height: number,
  layout: GraphLabel
): { nodes: JobNode[]; layout: GraphLabel } {
  // Fake the node list with the passed width and height
  return getLayoutedElements(
    nodes.map((e) => ({ ...e, measured: { height, width } })),
    edges,
    layout
  );
}
