import { Node, NodeProps } from '@xyflow/react';
import React from 'react';

import GenericNode from './GenericNode';
import { getNodeIcon } from './nodeIcons';
import { SubflowNodeData } from './SubflowNode';
import { AS_NODE_STATUS, AS_NODE_TYPES, AsNodesWithGateway } from './types';
import {
  generateReturnValues,
  getIncomingVarNameByHandleId,
  subflowFn,
  varname,
} from '../codegen';
import { AS_DATA_TYPES } from '../data.types';
import { useFlowDesignerUtils } from '../hooks';
import {
  BaseMetaParameter,
  GenerationProps,
  MetaInputParameter,
} from '../types';

export type MapNode = Node<SubflowNodeData, typeof AS_NODE_TYPES.MAP>;

export function isMapNode(node: AsNodesWithGateway): node is MapNode {
  return node.type === AS_NODE_TYPES.MAP;
}

export const HANDLE_ID_ITERABLE_IN = 'in-iterable' as const;
export const HANDLE_ID_ITERABLE_OUT = 'out-iterable' as const;

export const MAP_INPUTS: MetaInputParameter[] = [
  {
    id: HANDLE_ID_ITERABLE_IN,
    typeSchema: {
      type: AS_DATA_TYPES.LIST,
      items: {
        type: AS_DATA_TYPES.ANY,
      },
    },
    defaultLabel: 'Input iterable',
    isFixed: true,
  },
];

export const MAP_OUTPUTS: BaseMetaParameter[] = [
  {
    id: HANDLE_ID_ITERABLE_OUT,
    typeSchema: {
      type: AS_DATA_TYPES.LIST,
      items: {
        type: AS_DATA_TYPES.ANY,
      },
    },
    defaultLabel: 'Output iterable',
    isFixed: true,
  },
];

export const generateMapCode = ({
  context,
  node,
  flow,
  localStatePath,
}: GenerationProps<MapNode>) => {
  const subflow = flow.subflows.find(
    (subflow) => subflow.id === node.data.subflowId
  );
  const subflowName = subflowFn(context, subflow);

  const iterableVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    node.data.connections.inputs.find(
      (input) => input.id === HANDLE_ID_ITERABLE_IN
    ).id
  );
  const inputVars = node.data.connections.inputs
    .filter((input) => input.id !== HANDLE_ID_ITERABLE_IN)
    .map((input) => getIncomingVarNameByHandleId(context, flow, node, input.id))
    .join(', ');
  const outputVars = generateReturnValues(context, node);
  const outputIdsStr = node.data.connections.outputs
    .map((output) => `'${output.id}'`)
    .join(', ');

  // Need to set variables to none, so that the entryAllowed checks work
  const returnValueNames = node.data.connections.outputs.map((output) =>
    varname(context, node, output.id)
  );

  // Initialize return variables with None
  const initializeVariables =
    returnValueNames.length > 0
      ? `${returnValueNames.join(', ')} = ${returnValueNames
          .map((_) => 'None')
          .join(', ')}`
      : '';

  // bool to check if any params are None
  const entryAllowed = `all([param is not None for param in [${node.data.connections.inputs
    .map((input) => getIncomingVarNameByHandleId(context, flow, node, input.id))
    .join(',')}]])`;

  const functionCall = !outputVars
    ? `[${subflowName}(x, ${inputVars}) for x in ${iterableVar}]`
    : `${outputVars} = [${subflowName}(x, ${inputVars}) for x in ${iterableVar}]`;

  // bool to check if no return values are none
  const allSuccessful = `all([param is not None for param in [${returnValueNames.join(
    ','
  )}]])`;

  // Get all node IDs in the subflow to check their states
  const subflowNodeIds = subflow?.nodes?.map((n) => n.id) || [];
  const subflowNodeIdsStr = JSON.stringify(subflowNodeIds);

  const saveResults = `save_var_to_results('${
    node.id
  }', zip([${outputIdsStr}], [${returnValueNames.join(', ')}]))`;

  return `
### Executing Map Node "${subflowName}" ###
${initializeVariables}
if ${entryAllowed}:
  update_state('${node.id}', '${AS_NODE_STATUS.RUNNING}')
  try:
    with tee_output('${node.id}'):
      ${functionCall}
    
    # Check if any nodes inside the subflow are in error state
    has_error_nodes = False
    try:
      with open('${localStatePath}', 'r') as f:
        state_data = json.load(f)
              
      # Check each node in the subflow
      subflow_node_ids = ${subflowNodeIdsStr}
      for node_id in subflow_node_ids:
        if node_id in state_data and state_data[node_id] == '${AS_NODE_STATUS.ERROR}':
          has_error_nodes = True
          break
    except Exception:
      pass
    
    if has_error_nodes:
      # Set subflow node to ERROR state if any node inside the subflow is in ERROR state
      update_state('${node.id}', '${AS_NODE_STATUS.ERROR}')
    elif ${allSuccessful}:
      update_state('${node.id}', '${AS_NODE_STATUS.SUCCESS}')
      ${saveResults}
  
  except Exception:
    update_state('${node.id}', '${AS_NODE_STATUS.ERROR}')
    save_stacktrace_to_results('${node.id}', traceback.format_exc())
`.split('\n');
};

export function MapNode({ id, type, data }: NodeProps<MapNode>) {
  const { filePath, subflowId } = data;

  const { openSubflow } = useFlowDesignerUtils(filePath);

  return (
    <GenericNode
      id={id}
      name={'Map'}
      Icon={getNodeIcon(type)}
      onClick={() => openSubflow(subflowId)}
      {...data}
    />
  );
}
