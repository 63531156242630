import './style.css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { ReactFlowProvider } from '@xyflow/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { HotKeys } from 'react-hotkeys';
import { FiClipboard } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import ButtonBar from './ButtonBar';
import { codegen, resetNodesCodegen } from './codegen';
import { useCustomNodes, useWebSocketConnection } from './hooks';
import NodeConfigurationPage from './nodeConfigurationPage/NodeConfigurationPage';
import NodeResultPreview from './nodes/NodeResultPreview';
import { AsNodeStatus, AsNodesWithGateway } from './nodes/types';
import { FlowConfig } from './persisted.types';
import Prototype from './Prototype';
import styles from './styles.module.scss';
import { flowConfigToFlowData, flowDataToFlowConfig } from './transformation';
import { FlowData } from './types';
import { flowStatePath } from './utils';
import { useContent } from '../../../../../core/api/workbench/content';
import { saveNotebook } from '../../../../../redux/workbench/modules/notebook.module';
import { changeCodeContent } from '../../../../../redux/workbench/modules/notebook.source.module';
import { RootState, useAppDispatch } from '../../../../../store/store';
import {
  connectionCompleted,
  edgesChanged,
  flowOpened,
  nodesChanged,
  pathSelectionCleared,
  selectFlow,
  selectSelectedFlow,
  selectSelectedFlowPath,
  subflowDeselected,
} from '../../../../../store/workbench/flowDesigner.slice';
import {
  JUPYTER_CONTENT_TYPE,
  Session,
} from '../../../../../store/workbench/state.types';
import Busy from '../../../../atoms/busy/Busy';
import ClipboardWrapper from '../../../../molecules/clipboard/ClipboardWrapper';
import ErrorBoundary from '../../../../pages/error-boundary/ErrorBoundary';
import CloseConfirm from '../../../part-right/editor/close-confirm/CloseConfirm.container';
import { NodeRepository } from '../node-repository/NodeRepository';

export type Props = {
  /** Like flowdesigner.asr/2.asflow */
  path: string;
  content: string;
  unsavedChanges: boolean;
  showCloseConfirm: boolean;
  paneId: string;
  session?: Session;
};

const FlowDesigner: FC<Props> = ({
  path,
  content,
  unsavedChanges,
  showCloseConfirm,
  paneId,
  session,
}) => {
  const flowDesignerEnabled = useBooleanFlagValue('flow-designer', false);
  const dispatch = useAppDispatch();

  const flow = useSelector((state: RootState) => selectFlow(state, path));
  const selectedFlow = useSelector((state: RootState) =>
    selectSelectedFlow(state, path)
  );
  const selectedFlowPath = useSelector((state: RootState) =>
    selectSelectedFlowPath(state, path)
  );

  const { data: nodeDefinitions, isSuccess: isNodeDefinitionsSuccess } =
    useCustomNodes();

  useEffect(() => {
    // FIXME this initializes the redux state, but this is not a good way to do it
    //   This MUST only happen once as otherwise
    if (isNodeDefinitionsSuccess && !flow) {
      let initialFlowData: FlowData;
      try {
        const parsedContent: FlowConfig = JSON.parse(content);
        initialFlowData = flowConfigToFlowData(
          parsedContent,
          path,
          nodeDefinitions || []
        );
      } catch (e) {
        // Continuing here will leave you with an empty flow, so this seems worth an error log
        console.error(e);
      }

      dispatch(flowOpened({ filePath: path, flow: initialFlowData }));
    }
  }, [
    dispatch,
    path,
    content,
    flow,
    nodeDefinitions,
    isNodeDefinitionsSuccess,
  ]);

  // FIXME this is just to save, remove later
  useEffect(() => {
    if (!flow) return;

    const cleanedFlow: FlowConfig = flowDataToFlowConfig(flow);

    dispatch(
      changeCodeContent({
        path,
        content: JSON.stringify(cleanedFlow),
      })
    );
  }, [dispatch, path, flow]);

  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);

  const [activeSidebarTab, setActiveSidebarTab] = useState<
    'source' | 'log' | 'toolbar'
  >('toolbar');

  // CAUTION: This function has to be memoized as described in the reactflow docs: https://reactflow.dev/api-reference/hooks/use-on-selection-change
  //          This is true even though this is just a callback that gets called inside the memoized reactflow onChange function
  // I think this is a bad thing as it contradicts the React docs: https://react.dev/reference/react/memo#usage
  const onNodeSelectionChanged = useCallback((selectedNodes: string[]) => {
    setSelectedNodes(selectedNodes);
    setActiveSidebarTab('toolbar');
  }, []);

  const { log, sendExecuteRequest } = useWebSocketConnection(
    session?.id,
    session?.kernel?.id
  );

  // Node state
  let nodeResultState: Record<string, AsNodeStatus> = {};
  const { data: nodeResultStateData } = useContent(flowStatePath(path), true, {
    refetchInterval: 1000,
  });
  if (nodeResultStateData) {
    try {
      nodeResultState = JSON.parse(nodeResultStateData.content);
    } catch (e) {
      console.error('Could not parse node state', e);
    }
  }

  // Filled in a callback of the <AceEditor/>
  const keyMap = {
    save: 'command+s',
  };
  const keyHandlers = {
    save: (e: KeyboardEvent) => {
      // @ts-ignore
      dispatch(saveNotebook(path, content, 'file'));
      e.preventDefault();
    },
  };

  // Is the close confirm supposed to be shown?
  if (showCloseConfirm) {
    return (
      <CloseConfirm
        path={path}
        content={content}
        paneId={paneId}
        type={JUPYTER_CONTENT_TYPE.FILE}
      />
    );
  }

  if (!flowDesignerEnabled) {
    return <div>Flow Designer disabled</div>;
  }

  if (!selectedFlow) {
    return <Busy isBusy />;
  }

  const source = codegen(selectedFlow, nodeDefinitions || [], path);

  // Enrich the main flow nodes with current execution status
  // The "flow" represents the top-level flow data structure containing all nodes, edges and subflows
  // @ts-ignore
  const enrichedNodesMainFlow: AsNodesWithGateway[] = flow.nodes.map(
    (node) => ({
      ...node,
      data: {
        ...node.data,
        status: nodeResultState[node.id],
      },
    })
  );

  // Create an enriched version of the main flow with status information
  const enrichedMainFlow: FlowData = {
    ...flow,
    nodes: enrichedNodesMainFlow,
  };

  // Enrich the currently selected flow's nodes (might be a subflow if navigated into one)
  // "selectedFlow" represents the flow context that the user is currently viewing/editing
  // @ts-ignore
  const enrichedNodes: AsNodesWithGateway[] = selectedFlow.nodes.map(
    (node) => ({
      ...node,
      data: {
        ...node.data,
        status: nodeResultState[node.id],
        // Handle for triggering partial execution on a node
        // This executes only the selected node and its necessary predecessors
        sendExecuteNodeRequest: () => {
          // Generate code to reset the node before execution to ensure clean state
          const resetCode = resetNodesCodegen(flow, path, node.id);

          // Generate Python code for partial execution using the top-level enriched flow
          // We need the complete flow hierarchy (not just selectedFlow) to properly
          // determine all dependencies across subflows for hierarchical execution
          const partialExecutionCode = codegen(
            enrichedMainFlow,
            nodeDefinitions || [],
            path,
            undefined,
            node.id
          );

          const combinedCode = [
            '# Reset selected node and its successors',
            resetCode,
            '',
            '# Execute selected node and required predecessors',
            partialExecutionCode,
          ].join('\n');

          sendExecuteRequest(combinedCode);
        },
        // Handler for resetting the state of a node and all its successors
        sendResetNodeRequest: () => {
          sendExecuteRequest(resetNodesCodegen(flow, path, node.id));
        },
      },
    })
  );

  return (
    <div className={'code-content'}>
      <HotKeys className={'hotkeys'} keyMap={keyMap} handlers={keyHandlers}>
        <ButtonBar
          path={path}
          content={content}
          unsavedChanges={unsavedChanges}
          toggleSource={() =>
            setActiveSidebarTab(
              activeSidebarTab === 'source' ? 'toolbar' : 'source'
            )
          }
          toggleLog={() =>
            setActiveSidebarTab(activeSidebarTab === 'log' ? 'toolbar' : 'log')
          }
          sendExecuteRequest={() =>
            sendExecuteRequest(codegen(flow, nodeDefinitions || [], path))
          }
          onGoBackFlowPath={() =>
            dispatch(
              subflowDeselected({
                filePath: path,
              })
            )
          }
          onClearFlowPath={() =>
            dispatch(
              pathSelectionCleared({
                filePath: path,
              })
            )
          }
          activeSidebarTab={activeSidebarTab}
        />

        <ReactFlowProvider>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.flowContainer}>
                <Prototype
                  key={selectedFlow.id}
                  filePath={path}
                  nodes={enrichedNodes}
                  edges={selectedFlow.edges}
                  onNodesChanged={(changes) => {
                    dispatch(
                      nodesChanged({
                        filePath: path,
                        changes: changes,
                        selectedFlowPath,
                      })
                    );
                  }}
                  onEdgesChanged={(changes) =>
                    dispatch(
                      edgesChanged({
                        filePath: path,
                        changes,
                        selectedFlowPath,
                      })
                    )
                  }
                  onConnect={(connection) =>
                    dispatch(
                      connectionCompleted({
                        filePath: path,
                        selectedFlowPath,
                        connection,
                      })
                    )
                  }
                  onNodeSelectionChange={onNodeSelectionChanged}
                />
              </div>
              <div className={styles.bottomBar}>
                <ErrorBoundary>
                  <NodeResultPreview
                    selectedNodes={selectedNodes}
                    filePath={path}
                    selectedNodeState={
                      selectedNodes.length === 1
                        ? nodeResultState[selectedNodes[0]]
                        : null
                    }
                  />
                </ErrorBoundary>
              </div>
            </div>
            {/* Sidebar for Source/Log */}
            {selectedNodes.length !== 1 && (
              <div className={styles.sideBar}>
                {activeSidebarTab === 'toolbar' && <NodeRepository />}
                {activeSidebarTab === 'source' && (
                  <>
                    <h3 className={styles.sidebarHeader}>
                      Source
                      <ClipboardWrapper data={source}>
                        <FiClipboard className={styles.clipboard} />
                      </ClipboardWrapper>
                    </h3>
                    <div className={styles.codeContainer}>
                      <pre>{source}</pre>
                    </div>
                  </>
                )}
                {activeSidebarTab === 'log' && (
                  <>
                    <h3 className={styles.sidebarHeader}>
                      Log
                      <ClipboardWrapper data={log}>
                        <FiClipboard className={styles.clipboard} />
                      </ClipboardWrapper>
                    </h3>
                    <div className={styles.codeContainer}>
                      <pre>{log}</pre>
                    </div>
                  </>
                )}
              </div>
            )}
            {selectedNodes.length === 1 && (
              <div className={styles.sideBar}>
                <NodeConfigurationPage
                  key={selectedNodes[0]}
                  filePath={path}
                  selectedFlowPath={selectedFlowPath}
                  selectedNodeId={selectedNodes[0]}
                />
              </div>
            )}
          </div>
        </ReactFlowProvider>
      </HotKeys>
    </div>
  );
};

export default FlowDesigner;
