import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';

import styles from './styles.module.scss';
import { ThemeColor, ThemeColorModifier, useThemeColor } from '../../../utils';
import LoadingPlaceholder from '../loading-placeholder/LoadingPlaceholder';

export type Props = PropsWithChildren<{
  themeColor?: ThemeColor;
  modifier?: ThemeColorModifier;
  isLoading?: boolean;
}>;

const ThemedSwitch: FC<Props & ReactSwitchProps> = ({
  children,
  themeColor = 'primary',
  modifier,
  isLoading,
  disabled,
  className,
  ...props
}) => {
  const color = useThemeColor(themeColor, modifier);

  if (isLoading) {
    return (
      <div className={styles.loadingSwitch}>
        <LoadingPlaceholder width={'40px'} height={'20px'} />
      </div>
    );
  }

  return (
    <Switch
      onColor={color || undefined}
      {...props}
      disabled={disabled}
      className={classNames(styles.themedSwitch, className, {
        [styles.disabled]: disabled,
      })}
    />
  );
};

export default ThemedSwitch;
