import { ConditionalNode } from './ConditionalNode';
import { CustomPythonNode } from './CustomPythonNode';
import { GatewayNode } from './GatewayNode';
import { MapNode } from './MapNode';
import { SubflowNode } from './SubflowNode';
import { Connections } from '../types';

export const AS_NODE_TYPES = {
  CONDITIONAL: 'conditional',
  PYTHON_NODE: 'python_node',
  SUBFLOW: 'subflow',
  GATEWAY: 'gateway',
  MAP: 'map',
} as const;
export type AsNodeTypes = (typeof AS_NODE_TYPES)[keyof typeof AS_NODE_TYPES];

export const AS_NODE_STATUS = {
  UNCONFIGURED: 'unconfigured',
  WAITING: 'waiting',
  RUNNING: 'running',
  ERROR: 'error',
  SUCCESS: 'success',
} as const;
export type AsNodeStatus = (typeof AS_NODE_STATUS)[keyof typeof AS_NODE_STATUS];

export type GenericNodeData = {
  filePath: string;
  connections: Connections;
  highlight?: boolean;
  name?: string;
  /** Should not be saved to the file */
  status?: AsNodeStatus;
  /** Triggers partial execution of this node and its necessary predecessors */
  sendExecuteNodeRequest?: () => void;
  /** Resets execution state of this node and all its successors */
  sendResetNodeRequest?: () => void;
};

export type AsNodes =
  | ConditionalNode
  | CustomPythonNode
  | SubflowNode
  | MapNode;

export type AsNodesWithGateway = AsNodes | GatewayNode;
