import classNames from 'classnames';
import React, { FC } from 'react';
import { OptionProps } from 'react-select';

import styles from './styles.module.scss';

export type DefaultOptionType = { label: string; value: unknown };

export type DefaultOptionComponentProps = OptionProps<DefaultOptionType, false>;
const DefaultOptionComponent: FC<DefaultOptionComponentProps> = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(styles.defaultOptionComponent, {
        [styles.isSelected]: props.isSelected,
        [styles.isFocused]: props.isFocused,
      })}
      ref={innerRef}
      data-testid={props.data.label}
    >
      <span className={styles.label}>{props.data.label}</span>
    </div>
  );
};

export default DefaultOptionComponent;
