import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';
import { AS_NODE_STATUS } from './types';
import baseStyles from '../../../../../../../scss/base/var.module.scss';
import { InputParameter, Parameter } from '../types';

const getPositioningPercentage = (
  parameters: Parameter[],
  index: number
): string => `${(100 / (parameters.length + 1)) * (index + 1)}%`;

export function getHandleProps(
  parameter: InputParameter,
  handleType: 'target' | 'source',
  positioningPercentage: string
) {
  const commonClassNames = classNames(
    styles.defaultHandleStyle,
    styles.primitive,
    {
      [styles.target]: handleType === 'target',
      [styles.source]: handleType === 'source',
    }
  );

  const commonProps = {
    id: parameter.id,
    style: {
      top: positioningPercentage,
    },
  };

  switch (parameter.typeSchema?.type) {
    case 'string':
    case 'number':
    case 'bool': {
      return {
        ...commonProps,
        className: classNames(commonClassNames, styles.primitive, {
          [styles.boolean]: parameter.typeSchema.type === 'bool',
          [styles.number]: parameter.typeSchema.type === 'number',
          [styles.string]: parameter.typeSchema.type === 'string',
        }),
      };
    }
    case 'table': {
      return {
        ...commonProps,
        className: classNames(commonClassNames, styles.table),
      };
    }
    case 'list': {
      return {
        ...commonProps,
        className: classNames(commonClassNames, styles.list),
      };
    }
    default: {
      return {
        ...commonProps,
        className: classNames(commonClassNames, styles.any),
      };
    }
  }
}

export function generateHandles(
  parameters: Parameter[],
  handleType: 'target' | 'source'
) {
  const parametersWithHandle = parameters.filter(
    (parameter: InputParameter) =>
      !parameter.isConfigParameter || parameter.isDynamic
  );

  const getPositioningPercentage = (index: number): string =>
    `${(100 / (parametersWithHandle.length + 1)) * (index + 1)}%`;

  return (
    <>
      {parametersWithHandle.map((parameter, i) => {
        const props = getHandleProps(
          parameter,
          handleType,
          getPositioningPercentage(i)
        );
        return (
          <Handle
            key={parameter.id}
            {...props}
            type={handleType}
            position={handleType === 'target' ? Position.Left : Position.Right}
          />
        );
      })}
    </>
  );
}

export function generatePseudoHandles(
  parameters: Parameter[],
  handleType: 'target' | 'source'
) {
  const parametersWithHandle = parameters.filter(
    (parameter: InputParameter) =>
      !parameter.isConfigParameter || parameter.isDynamic
  );

  return (
    <>
      {parametersWithHandle.map((parameter, i) => {
        const props = getHandleProps(
          parameter,
          handleType,
          getPositioningPercentage(parametersWithHandle, i)
        );
        return <div key={parameter.id} {...props} />;
      })}
    </>
  );
}

export const statusColorMapping = {
  [AS_NODE_STATUS.UNCONFIGURED]: baseStyles.colorOrange,
  [AS_NODE_STATUS.WAITING]: baseStyles.colorGrey,
  [AS_NODE_STATUS.RUNNING]: baseStyles.colorBlue,
  [AS_NODE_STATUS.ERROR]: baseStyles.colorRed,
  [AS_NODE_STATUS.SUCCESS]: baseStyles.colorGreen,
};
