import type { Node, NodeProps } from '@xyflow/react';
import React from 'react';

import GenericNode from './GenericNode';
import { getNodeIcon } from './nodeIcons';
import { AS_NODE_TYPES, AsNodes, GenericNodeData } from './types';
import { AS_DATA_TYPES } from '../data.types';
import { BaseMetaParameter, MetaInputParameter } from '../types';

export type ConditionalNodeData = GenericNodeData;

export type ConditionalNode = Node<
  ConditionalNodeData,
  typeof AS_NODE_TYPES.CONDITIONAL
>;

export function isConditionalNode(node: AsNodes): node is ConditionalNode {
  return node.type === AS_NODE_TYPES.CONDITIONAL;
}

export const HANDLE_ID_CONDITION = 'in-conditional' as const;
export const HANDLE_ID_TRUE = 'in-true' as const;
export const HANDLE_ID_FALSE = 'in-false' as const;

export const CONDITIONAL_INPUTS: MetaInputParameter[] = [
  {
    id: HANDLE_ID_TRUE,
    typeSchema: {
      type: AS_DATA_TYPES.ANY,
    },
    defaultLabel: 'True',
  },
  {
    id: HANDLE_ID_CONDITION,
    typeSchema: {
      type: AS_DATA_TYPES.BOOL,
    },
    defaultLabel: 'Condition',
  },
  {
    id: HANDLE_ID_FALSE,
    typeSchema: {
      type: AS_DATA_TYPES.ANY,
    },
    defaultLabel: 'False',
  },
];

export const CONDITIONAL_OUTPUTS: BaseMetaParameter[] = [
  {
    id: 'conditional_out',
    typeSchema: {
      type: AS_DATA_TYPES.ANY,
    },
    defaultLabel: 'Conditional result',
  },
];

export function ConditionalNode({
  id,
  type,
  data,
}: NodeProps<ConditionalNode>) {
  return <GenericNode id={id} name={'If'} Icon={getNodeIcon(type)} {...data} />;
}
