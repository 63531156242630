import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component, FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { InjectedFormProps, WrappedFieldProps } from 'redux-form';

import { fieldComponents, fieldName, formName } from './CreateTemplate.form';
import { ButtonProps } from '../../../atoms/button/Button';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';
import { Checkbox } from '../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import ErrorBoundary from '../../../pages/error-boundary/ErrorBoundary';
import Wizard from '../../../pages/wizard/Wizard';
import { maintenanceRoutes } from '../routes';
import {
  Components,
  ComponentsSpeaking,
  TemplateCreateRequest,
} from '../types';

export type Props = {
  /** Is the form valid (= can the form be submitted)?*/
  isValid: boolean;
  /** Is the form currently submitting? (-> disable the Submit button and show a spinner in it)*/
  submitting: boolean;
  /** The values entered in the form */
  formValues: {
    name?: string;
    components?: string[];
  };
  createTemplate(
    templateCreate: TemplateCreateRequest,
    callback: () => void
  ): void;
};

const StepComponents: FC<WrappedFieldProps> = ({ input }) => {
  return (
    <div>
      {Object.entries(Components).map(([k, v]) => {
        const checked = input.value.includes(v);
        return (
          <div style={{ marginBottom: '5px' } /** TODO */}>
            <Checkbox
              checked={checked}
              disabled={false}
              onChange={() => {
                if (checked) {
                  input.onChange(input.value.filter((c) => c !== v));
                } else {
                  input.onChange([...input.value, v]);
                }
              }}
              label={ComponentsSpeaking[k]}
            />
          </div>
        );
      })}
    </div>
  );
};

const StepName: FC<WrappedFieldProps> = (props) => {
  return (
    <TextInputLine
      hasLabel
      labelDefault={'Template Name'}
      placeholderDefault={'Enter Template Name'}
      onFocus={props.input.onFocus}
      onChange={props.input.onChange}
      onBlur={props.input.onBlur}
      value={props.input.value}
      error={props.meta.error}
      touched={props.meta.touched}
    />
  );
};

class CreateTemplate extends Component<
  Props & InjectedFormProps<ToBeRefined, Props> & RouteComponentProps
> {
  render() {
    const {
      isValid,
      submitting,
      createTemplate,
      formValues,
      history,
    } = this.props;

    const buttons: ButtonProps[] = [
      {
        linkTo: `${maintenanceRoutes.basePath}/${maintenanceRoutes.templates.path}`,
        color: 'white',
        label: 'Cancel',
      },
      {
        color: 'secondary',
        label: 'Finish',
        disabled: !isValid,
        isBusy: submitting,
        onClick: () => {
          if (isValid) {
            const templateCreate = {
              name: formValues.name,
              components: formValues.components.map((name) => ({ name })),
            };
            createTemplate(templateCreate, () =>
              history.push(
                `${maintenanceRoutes.basePath}/${maintenanceRoutes.templates.path}`
              )
            );
          }
        },
      },
    ];

    return (
      <ErrorBoundary>
        <Wizard headline={'Create Template'} buttons={buttons}>
          <GenericFormStep
            fieldName={fieldName}
            formName={formName}
            component={StepName}
            title={{
              id: 'todo',
              defaultMessage: 'Select name',
            }}
            description={{
              id: 'todo',
              defaultMessage: `Give the template a descriptive name: e.g. "full-backup".`,
            }}
            num={1}
            renderError={false}
          />

          <GenericFormStep
            fieldName={fieldComponents}
            formName={formName}
            component={StepComponents}
            title={{
              id: 'todo',
              defaultMessage: 'Select components',
            }}
            description={{
              id: 'todo',
              defaultMessage: 'Select the components you want to backup.',
            }}
            num={2}
            renderError={true}
          />
        </Wizard>
      </ErrorBoundary>
    );
  }
}

export default withRouter(CreateTemplate);
