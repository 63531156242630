import { useUpdateNodeInternals } from '@xyflow/react';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { IconType } from 'react-icons';
import { FiPlayCircle, FiRefreshCcw } from 'react-icons/fi';

import styles from './styles.module.scss';
import { GenericNodeData } from './types';
import {
  generateHandles,
  generatePseudoHandles,
  statusColorMapping,
} from './utils';

type Props = Omit<GenericNodeData, 'filePath'> & {
  id: string;
  Icon: IconType;
  onClick?: () => void;
};

export const GenericNode: FC<Props> = ({
  id,
  name,
  connections,
  status,
  Icon,
  onClick,
  sendExecuteNodeRequest,
  sendResetNodeRequest,
}) => {
  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    // We need to call updateNodeIntervals after adding/or removing handles (see https://reactflow.dev/api-reference/hooks/use-update-node-internals for more info)
    // Because we use redux for the flow state, calling updateNodeInternals directly after adding handles doesn't work because the Redux event runs async
    // Therefore, we need to wait for the event to take effect and watch for changes in data.connections to then call updateNodeIntervals
    updateNodeInternals(id);
    // DO NOT REMOVE THE connections DEPENDENCY
  }, [updateNodeInternals, id, connections]);
  const { inputs, outputs } = connections;

  return (
    <div className={styles.nodeContainer}>
      <div className={styles.hoverButtonsContainer}>
        {sendExecuteNodeRequest && (
          <div
            title='Execute Node'
            className={styles.nodeButton}
            onClick={sendExecuteNodeRequest}
          >
            <FiPlayCircle size='14px' />
          </div>
        )}
        {sendResetNodeRequest && (
          <div
            title='Reset Node'
            className={styles.nodeButton}
            onClick={sendResetNodeRequest}
          >
            <FiRefreshCcw size='14px' />
          </div>
        )}
      </div>
      <div
        className={classNames(styles.defaultNodeStyle, {
          [styles.clickable]: !!onClick,
          [styles.running]: status === 'running',
        })}
        onClick={onClick}
      >
        {status && (
          <div
            className={styles.status}
            style={{ backgroundColor: statusColorMapping[status] }}
          />
        )}
        <Icon />
      </div>
      {generateHandles(inputs, 'target')}
      {generateHandles(outputs, 'source')}
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export const GenericPseudoNode: FC<
  Pick<Props, 'name' | 'Icon' | 'connections'>
> = ({ name, connections, Icon }) => {
  const { inputs, outputs } = connections;

  return (
    <div className={styles.nodeContainer}>
      <div className={styles.defaultNodeStyle}>
        <Icon />
      </div>
      {generatePseudoHandles(inputs, 'target')}
      {generatePseudoHandles(outputs, 'source')}
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default GenericNode;
