/**
 * Get the directory portion from a path. Should be the same as nodes path.dirname
 *
 * @param path e.g. flowdesigner.asr/2.asflow
 * @return e.g. flowdesigner.asr
 */
export function dirname(path: string): string {
  return path.substring(0, path.lastIndexOf('/'));
}

/**
 * Get the file name portion from a path. Should be the same as nodes path.basename
 * If the path is a dir but has no trailing slash it works the same
 *
 * @param e.g. path flowdesigner.asr/2.asflow
 * @return e.g. flowdesigner.asr
 */
export function basename(path: string): string {
  return path.substring(path.lastIndexOf('/') + 1);
}

export const flowFileExtension = '.asflow';
export const flowStateFileName = 'state.json';

/**
 * Return the asflow directory for saving preview results, state,...
 * Works for both a passed full path or just a filename
 *
 * @param flowFilePath e.g. <.../>foo.asflow
 * @return <.../>.foo_asflow
 */
export function flowResultsDir(flowFilePath: string): string {
  console.assert(
    flowFilePath.endsWith(flowFileExtension),
    `${flowFilePath} does not end with ${flowFileExtension}`
  );
  const flowDirName = dirname(flowFilePath);
  const flowBaseName = basename(flowFilePath);
  const stem = flowBaseName.slice(0, -flowFileExtension.length);
  return (flowDirName ? flowDirName + '/' : '') + `.${stem}_asflow`;
}

export function flowStatePath(flowFilePath: string): string {
  return flowResultsDir(flowFilePath) + '/' + flowStateFileName;
}

export function flowNodeResultPath(
  flowFilePath: string,
  nodeId: string
): string {
  return flowResultsDir(flowFilePath) + '/' + `${nodeId}.json`;
}

export function flowNodeLogsPath(flowFilePath: string, nodeId: string): string {
  return flowResultsDir(flowFilePath) + '/' + `${nodeId}.log`;
}
