import React, { FC, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MultiValue } from 'react-select';

import Combobox from '../../../../../atoms/combobox/Combobox';
import { DefaultOptionType } from '../../../../../atoms/input-elements/dropdown-select-input/DefaultOptionComponent';
import TabRadioGroup from '../../../../../atoms/input-elements/tab-radio-group/TabRadioGroup';
import TextInputSearch from '../../../../../atoms/input-elements/text-input-search/TextInputSearch';
import IntlTextInputLine from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import TransferList from '../../../../../molecules/transfer-list/TransferList';
import {
  ConfigComponentProps,
  ConfigFormTransform,
  GenericConfigParameter,
} from '../../component/nodeConfigurationPage/NodeConfigurationPage';

// Available column types for the column_type filter
const columnTypeOptions = [
  { label: 'Int', value: 'int' },
  { label: 'Float', value: 'float' },
  { label: 'String', value: 'string' },
  { label: 'Boolean', value: 'boolean' },
  { label: 'Timestamp', value: 'timestamp' },
  { label: 'Object', value: 'object' },
];

type ColumnFilterConfig = {
  filterType: string;
  includes: string[];
  keepRegexPattern: string;
  keepWildcardPattern: string;
  keepColumnTypes: string[];
};

type ColumnFilterConfigOptions = {
  includes: string[];
};

export type Props = ConfigComponentProps<ColumnFilterConfigOptions>;

const FilterTypeSelector: FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  return (
    <TabRadioGroup
      options={[
        {
          id: 'manual',
          label: 'Manual',
        },
        {
          id: 'regex',
          label: 'Regex',
        },
        {
          id: 'wildcard',
          label: 'Wildcard',
        },
        {
          id: 'column_type',
          label: 'Column Type',
        },
      ]}
      selected={value}
      onChange={onChange}
    />
  );
};

const ManualFilterSection: FC<{
  includes: string[];
  includesOptions: string[];
  isIncludesDynamic: boolean;
  onDynamicChange: (parameterId: string, isDynamic: boolean) => void;
  onValueChange: (parameterId: string, value: unknown) => void;
}> = ({
  includes,
  includesOptions,
  isIncludesDynamic,
  onDynamicChange,
  onValueChange,
}) => {
  const { control } = useFormContext<ConfigFormTransform<ColumnFilterConfig>>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <>
      <TextInputSearch
        initialValue={searchQuery}
        submitSearchQuery={setSearchQuery}
        submitOnChange
      />
      <Controller
        name='includes.value'
        control={control}
        disabled={isIncludesDynamic}
        render={({ field }) => {
          const { onChange } = field;

          return (
            <TransferList
              leftTitle='Drop'
              rightTitle='Keep'
              leftItems={includesOptions.filter(
                (val) => !includes.includes(val)
              )}
              rightItems={includes}
              onChange={(_leftItems, rightItems) => {
                onValueChange('includes', rightItems);
                onChange(rightItems);
              }}
              searchQuery={searchQuery}
            />
          );
        }}
      />
    </>
  );
};

const RegexFilterSection: FC<{
  isKeepRegexPatternDynamic: boolean;
  onDynamicChange: (parameterId: string, isDynamic: boolean) => void;
  onValueChange: (parameterId: string, value: unknown) => void;
  columnOptions?: string[];
}> = ({
  isKeepRegexPatternDynamic,
  onDynamicChange,
  onValueChange,
  columnOptions,
}) => {
  const { control, watch } =
    useFormContext<ConfigFormTransform<ColumnFilterConfig>>();
  const regexPattern = (watch('keepRegexPattern.value') as string) || '';

  // Find matching and non-matching columns for preview
  const { matchingColumns, nonMatchingColumns } = useMemo(() => {
    try {
      if (!regexPattern)
        return { matchingColumns: columnOptions, nonMatchingColumns: [] };

      const regex = new RegExp(regexPattern);
      return {
        matchingColumns: columnOptions.filter((col) => regex.test(col)),
        nonMatchingColumns: columnOptions.filter((col) => !regex.test(col)),
      };
    } catch (e) {
      // Bei ungültigem Regex leere Arrays zurückgeben
      return { matchingColumns: [], nonMatchingColumns: [] };
    }
  }, [columnOptions, regexPattern]);

  return (
    <>
      <Controller
        name='keepRegexPattern.value'
        control={control}
        disabled={isKeepRegexPatternDynamic}
        rules={{
          validate: (value) => {
            try {
              new RegExp(value);
              return true;
            } catch (e) {
              return 'Invalid regex pattern';
            }
          },
        }}
        render={({ field, fieldState }) => {
          const { ref, value, onChange, ...rest } = field;
          const { error, ...restFieldState } = fieldState;

          return (
            <IntlTextInputLine
              inputRef={ref}
              value={String(value ?? '')}
              onChange={(e) => {
                const value = e.target.value;
                onChange?.(value);
                onValueChange('keepRegexPattern', value);
              }}
              error={error?.message}
              placeholder={'Pattern'}
              {...rest}
              {...restFieldState}
              isTouched={true}
            />
          );
        }}
      />
      {/* Preview selected columns */}
      <TransferList
        leftTitle='Drop'
        rightTitle='Keep'
        leftItems={nonMatchingColumns}
        rightItems={matchingColumns}
        onChange={() => {}}
        disabled={true}
      />
    </>
  );
};

const WildcardFilterSection: FC<{
  isKeepWildcardPatternDynamic: boolean;
  onDynamicChange: (parameterId: string, isDynamic: boolean) => void;
  onValueChange: (parameterId: string, value: unknown) => void;
  columnOptions?: string[];
}> = ({
  isKeepWildcardPatternDynamic,
  onDynamicChange,
  onValueChange,
  columnOptions,
}) => {
  const { control } = useFormContext<ConfigFormTransform<ColumnFilterConfig>>();

  return (
    <>
      <Controller
        name='keepWildcardPattern.value'
        control={control}
        disabled={isKeepWildcardPatternDynamic}
        render={({ field, fieldState }) => {
          const { ref, value, onChange, ...rest } = field;
          const { error, ...restFieldState } = fieldState;

          return (
            <IntlTextInputLine
              inputRef={ref}
              value={String(value ?? '')}
              onChange={(e) => {
                const value = e.target.value;
                onChange?.(value);
                onValueChange('keepWildcardPattern', value);
              }}
              error={error?.message}
              placeholder={'Pattern'}
              {...rest}
              {...restFieldState}
              isTouched={true}
            />
          );
        }}
      />
      {/* Preview selected columns */}
      <TransferList
        leftTitle='Drop'
        rightTitle='Keep'
        leftItems={['Apple', 'Banana']}
        rightItems={['Coconut', 'Date']}
        onChange={() => {}}
        disabled={true}
      />
    </>
  );
};

const ColumnTypeFilterSection: FC<{
  isKeepColumnTypesDynamic: boolean;
  onDynamicChange: (parameterId: string, isDynamic: boolean) => void;
  onValueChange: (parameterId: string, value: unknown) => void;
  columnOptions?: string[];
}> = ({
  isKeepColumnTypesDynamic,
  onDynamicChange,
  onValueChange,
  columnOptions,
}) => {
  const { control } = useFormContext<ConfigFormTransform<ColumnFilterConfig>>();

  return (
    <>
      <Controller
        name='keepColumnTypes.value'
        control={control}
        disabled={isKeepColumnTypesDynamic}
        rules={{
          validate: (value) => {
            if (!value) {
              return 'Select at least one column type';
            }
            return true;
          },
        }}
        render={({ field, fieldState }) => {
          const { ref, value, onChange, ...rest } = field;
          const { error, ...restFieldState } = fieldState;

          const optionValue = (value as unknown[])?.map(
            (typeValue) =>
              ({
                label: String(typeValue),
                value: typeValue,
              } satisfies DefaultOptionType)
          );

          return (
            <Combobox
              inputRef={ref}
              {...rest}
              {...restFieldState}
              error={error?.message}
              value={optionValue}
              isMulti={true}
              onChange={(selected) => {
                const newValue = (
                  selected as MultiValue<DefaultOptionType>
                ).map((option) => option.value);
                onChange?.(newValue);
                onValueChange('keepColumnTypes', newValue);
              }}
              options={columnTypeOptions}
            />
          );
        }}
      />
      {/* Preview selected columns */}
      <TransferList
        leftTitle='Drop'
        rightTitle='Keep'
        leftItems={['Apple', 'Banana']}
        rightItems={['Coconut', 'Date']}
        onChange={() => {}}
        disabled={true}
      />
    </>
  );
};

const ColumnFilterConfig: FC<Props> = ({
  onValueChange,
  onDynamicChange,
  configOptions,
}) => {
  const { includes: includesOptions = [] } = configOptions || {};
  const { control, getValues, watch } =
    useFormContext<ConfigFormTransform<ColumnFilterConfig>>();

  // Watch filter type to conditionally render sections
  const filterType = watch('filterType.value') as string;

  const isDisabled = (parameterId: keyof ColumnFilterConfig) => {
    return getValues(`${parameterId}.isDynamic`);
  };

  return (
    <>
      <Controller
        name='filterType.value'
        control={control}
        disabled={isDisabled('filterType')}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <FilterTypeSelector
              value={String(value)}
              onChange={(newValue) => {
                onChange?.(newValue);
                onValueChange('filterType', newValue);
              }}
            />
          );
        }}
      />

      {filterType === 'manual' && (
        <ManualFilterSection
          includes={getValues('includes.value') || []}
          includesOptions={includesOptions}
          isIncludesDynamic={isDisabled('includes')}
          onDynamicChange={onDynamicChange}
          onValueChange={onValueChange}
        />
      )}

      {filterType === 'regex' && (
        <RegexFilterSection
          isKeepRegexPatternDynamic={isDisabled('keepRegexPattern')}
          columnOptions={includesOptions}
          onDynamicChange={onDynamicChange}
          onValueChange={onValueChange}
        />
      )}

      {filterType === 'wildcard' && (
        <WildcardFilterSection
          isKeepWildcardPatternDynamic={isDisabled('keepWildcardPattern')}
          columnOptions={includesOptions}
          onDynamicChange={onDynamicChange}
          onValueChange={onValueChange}
        />
      )}

      {filterType === 'column_type' && (
        <ColumnTypeFilterSection
          isKeepColumnTypesDynamic={isDisabled('keepColumnTypes')}
          columnOptions={includesOptions}
          onDynamicChange={onDynamicChange}
          onValueChange={onValueChange}
        />
      )}
    </>
  );
};

export default ColumnFilterConfig;
