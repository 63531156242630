import { createReducer } from 'redux-act';

import { RootActions } from './actions';
import { adminBackupsReducer } from './admin/backups/reducer';
import { adminMaintenanceReducer } from './admin/maintenance/reducer';
import { adminResourcesReducer } from './admin/resources/reducer';
import { auditLogReducer } from './auditLog/slice';
import { augurBiographyReducer } from './augurBiography/slice';
import { jobGroupsReducer } from './jobGroups/slice';
import { realtimeAugurReducer } from './realtime/slice';
import * as states from './state';
import { DeprecatedRootState } from './state.type';
import { workbenchTerminalsReducer } from './workbenchTerminals/slice';
import { multiReportReducer } from '../components/pages/augur/utils/multiReportWrapper.state';
import * as adminUsers from '../redux/modules/admin.users.module';
import * as appsModule from '../redux/modules/apps.module';
import * as authModule from '../redux/modules/auth.module';
import * as config from '../redux/modules/config.module';
import * as dashboardInit from '../redux/modules/dashboard.init.module';
import * as dashboardModule from '../redux/modules/dashboard.module';
import * as dashboardSearchModule from '../redux/modules/dashboard.search.module';
import * as dataCassandraModule from '../redux/modules/data.cassandra.module';
import * as dataSourcesModule from '../redux/modules/data.dataSources.module';
import * as dataS3Module from '../redux/modules/data.s3.module';
import * as menuModule from '../redux/modules/menu.module';
import * as notificationsModule from '../redux/modules/notifications.module';
import * as codeCapsuleReportModule from '../redux/modules/orchestration.codeCapsuleReport.module';
import * as orchestrationHistory from '../redux/modules/orchestration.history.module';
import * as orchestrationJobConfigs from '../redux/modules/orchestration.jobconfigs.module';
import * as orchstrationJobDetails from '../redux/modules/orchestration.jobdetails.module';
import * as orchestrationJobQueue from '../redux/modules/orchestration.queue.module';
import * as orchstrationSchedules from '../redux/modules/orchestration.schedules.module';
import * as pageModule from '../redux/modules/page.module';

// DEPRECATED! Use the  typedReducer instead
export default createReducer(
  {
    ...authModule.reducer,
    ...dashboardModule.reducer,
    ...menuModule.reducer,
    ...dashboardSearchModule.reducer,
    ...notificationsModule.reducer,
    ...pageModule.reducer,
    ...adminUsers.reducer,
    ...dashboardInit.reducer,
    ...orchestrationJobQueue.reducer,
    ...orchestrationJobConfigs.reducer,
    ...orchestrationHistory.reducer,
    ...orchstrationSchedules.reducer,
    ...orchstrationJobDetails.reducer,
    ...config.reducer,
    // ---
    ...codeCapsuleReportModule.reducer,
    ...appsModule.reducer,
    ...dataSourcesModule.reducer,
    ...dataCassandraModule.reducer,
    ...dataS3Module.reducer,
  },
  states.initial
);

// --- MIGRATED TO TYPESCRIPT
// Can't use new RootState, because then it would circularly reference itself
export const typedReducer = (
  state: DeprecatedRootState,
  action: RootActions
) => ({
  // @ts-ignore
  adminResources: adminResourcesReducer(state, action),
  // @ts-ignore
  adminBackups: adminBackupsReducer(state, action),
  // @ts-ignore
  adminMaintenance: adminMaintenanceReducer(state, action),
  augurBiography: augurBiographyReducer(state.augurBiography, action),
  jobGroups: jobGroupsReducer(state.jobGroups, action),
  auditLog: auditLogReducer(state.auditLog, action),
  workbenchTerminals: workbenchTerminalsReducer(
    state.workbenchTerminals,
    action
  ),
  realtimeAugur: realtimeAugurReducer(state.realtimeAugur, action),
  timeline: multiReportReducer(state.timeline, action),
});
