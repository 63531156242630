import React, { FC, PropsWithChildren, useState } from 'react';
import { useDrag } from 'react-dnd';

import styles from './styles.module.scss';
import TextInputSearch from '../../../../atoms/input-elements/text-input-search/TextInputSearch';
import CollapsibleHeadlineArea from '../../../../organisms/collapsible-container/collapsible-headline-area/CollapsibleHeadlineArea';
import { useCustomNodes } from '../component/hooks';
import {
  CONDITIONAL_INPUTS,
  CONDITIONAL_OUTPUTS,
} from '../component/nodes/ConditionalNode';
import { GenericPseudoNode } from '../component/nodes/GenericNode';
import { MAP_INPUTS, MAP_OUTPUTS } from '../component/nodes/MapNode';
import { getNodeIcon } from '../component/nodes/nodeIcons';
import { AS_NODE_TYPES, AsNodeTypes } from '../component/nodes/types';
import { MetaConnections } from '../component/types';

export type ToolbarNode = {
  name: string;
  category: string;
  type: AsNodeTypes;
  // set for python nodes
  pythonNodeType?: string;
  connections: MetaConnections;
};

export const NodeRepository: FC = () => {
  const { data: nodeDefinitions } = useCustomNodes();
  const [searchQuery, setSearchQuery] = useState('');

  const toolbarNodes: ToolbarNode[] = [
    {
      name: 'Conditional',
      category: 'util',
      type: AS_NODE_TYPES.CONDITIONAL,
      connections: {
        inputs: CONDITIONAL_INPUTS,
        outputs: CONDITIONAL_OUTPUTS,
      },
    },
    {
      name: 'Subflow',
      category: 'util',
      type: AS_NODE_TYPES.SUBFLOW,
      connections: {
        inputs: [],
        outputs: [],
      },
    },
    {
      name: 'Map',
      category: 'Loops',
      type: AS_NODE_TYPES.MAP,
      connections: {
        inputs: MAP_INPUTS,
        outputs: MAP_OUTPUTS,
      },
    },
    ...(nodeDefinitions || []).map((x) => ({
      type: AS_NODE_TYPES.PYTHON_NODE,
      pythonNodeType: x.type,
      name: x.title,
      category: x.category,
      connections: {
        inputs: x.connections.inputs,
        outputs: x.connections.outputs,
      },
    })),
  ];

  const categorizedNodes: Record<string, ToolbarNode[]> = {};
  toolbarNodes
    .filter((node) =>
      node.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .forEach((toolbarNode) => {
      categorizedNodes[toolbarNode.category] = [
        ...(categorizedNodes[toolbarNode.category] || []),
        toolbarNode,
      ];
    });

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <TextInputSearch
          submitSearchQuery={setSearchQuery}
          submitOnChange={true}
        />
      </div>
      {Object.keys(categorizedNodes)
        .sort()
        .map((category) => {
          return (
            <CollapsibleHeadlineArea key={category} title={category}>
              <div className={styles.nodeGrid}>
                {categorizedNodes[category].map((toolbarNode) => {
                  return (
                    <DraggableNode key={toolbarNode.name} node={toolbarNode}>
                      <GenericPseudoNode
                        name={toolbarNode.name}
                        connections={toolbarNode.connections}
                        Icon={getNodeIcon(
                          toolbarNode.type,
                          toolbarNode.pythonNodeType
                        )}
                      />
                    </DraggableNode>
                  );
                })}
              </div>
            </CollapsibleHeadlineArea>
          );
        })}
    </div>
  );
};

const DraggableNode: FC<PropsWithChildren<{ node: ToolbarNode }>> = ({
  node,
  children,
}) => {
  const [, drag] = useDrag({
    item: {
      type: 'fdNode',
      node: node,
    },
  });
  return (
    <div className={styles.draggableNode} ref={drag}>
      {children}
    </div>
  );
};
