import { Node, NodeProps, useUpdateNodeInternals } from '@xyflow/react';
import React, { useEffect } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import { isMapNode, MAP_INPUTS, MAP_OUTPUTS, MapNode } from './MapNode';
import { SubflowNode } from './SubflowNode';
import { AS_NODE_TYPES, AsNodesWithGateway, GenericNodeData } from './types';
import { generateHandles } from './utils';
import { emptyFlowData } from '../../../../../../store/workbench/flowDesigner.slice';
import Button from '../../../../../atoms/button/Button';
import { AS_DATA_TYPES } from '../data.types';
import { useFlowDesignerUtils } from '../hooks';
import { FlowData, Parameter } from '../types';

export type GatewayNodeData = GenericNodeData & {
  gatewayType: 'in' | 'out';
  editable: boolean;
};

export type GatewayNode = Node<GatewayNodeData, 'gateway'>;

export function isSubflowGatewayNode(
  node: AsNodesWithGateway
): node is GatewayNode {
  return node.type === AS_NODE_TYPES.GATEWAY;
}

export function createEmptySubflow(
  filePath: string,
  node: SubflowNode | MapNode
): FlowData {
  let subflowInputs: Parameter[] = [];
  let subflowOutputs: Parameter[] = [];
  if (isMapNode(node)) {
    subflowInputs = MAP_INPUTS;
    subflowOutputs = MAP_OUTPUTS;
  }

  return {
    ...emptyFlowData,
    id: uuidv4(),
    parentNodeId: node.id,
    nodes: [
      {
        id: uuidv4(),
        type: 'gateway',
        data: {
          filePath,
          connections: {
            inputs: [],
            // inversion because subflow inputs are input gateway node outputs
            outputs: subflowInputs,
          },
          editable: true,
          gatewayType: 'in',
        },
        position: {
          x: -250,
          y: 0,
        },
      } satisfies GatewayNode,
      {
        id: uuidv4(),
        type: 'gateway',
        data: {
          filePath,
          connections: {
            outputs: [],
            // inversion because subflow outputs are output gateway node inputs
            inputs: subflowOutputs,
          },
          // only standard subflows have editable outputs
          editable: node.type === AS_NODE_TYPES.SUBFLOW,
          gatewayType: 'out',
        },
        position: {
          x: 250,
          y: 0,
        },
      } satisfies GatewayNode,
    ],
  };
}

export function GatewayNode({ id, data }: NodeProps<GatewayNode>) {
  const {
    filePath,
    editable,
    gatewayType,
    connections: { inputs, outputs },
  } = data;

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    // We need to call updateNodeIntervals after adding/or removing handles (see https://reactflow.dev/api-reference/hooks/use-update-node-internals for more info)
    // Because we use redux for the flow state, calling updateNodeInternals directly after adding handles doesn't work because the Redux event runs async
    // Therefore, we need to wait for the event to take effect and watch for changes in data.connections to then call updateNodeIntervals
    updateNodeInternals(id);
    // DO NOT REMOVE THE data.connections DEPENDENCY
  }, [updateNodeInternals, id, data.connections]);

  const { addParameter, removeParameter } = useFlowDesignerUtils(filePath);

  const addHandle = () => {
    addParameter(id, gatewayType, {
      id: uuidv4(),
      typeSchema: {
        type: AS_DATA_TYPES.ANY,
      },
    });
  };

  const removeHandle = () => {
    const lastHandle = gatewayType === 'in' ? outputs.at(-1) : inputs.at(-1);
    if (lastHandle && !lastHandle.isFixed) {
      removeParameter(id, gatewayType, lastHandle.id);
    }
  };

  return (
    // We add this class to use the same styles as React Flow's default nodes.
    <div
      className='react-flow__node-default'
      style={{
        width: '30px',
        height: '350px',
        background: 'lightgray',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0 8px 0',
      }}
    >
      {editable && (
        <Button
          form={'squared'}
          title={'Add handle'}
          Icon={FiPlus}
          style={{
            height: '16px',
            width: '16px',
          }}
          onClick={addHandle}
        />
      )}
      {editable && (
        <Button
          form={'squared'}
          title={'Remove handle'}
          Icon={FiMinus}
          style={{
            height: '16px',
            width: '16px',
          }}
          onClick={removeHandle}
        />
      )}
      {gatewayType === 'in' && generateHandles(outputs, 'source')}
      {gatewayType === 'out' && generateHandles(inputs, 'target')}
    </div>
  );
}
