import { ComponentType } from 'react';

import ColumnFilterConfig from './column-filter/ColumnFilterConfig';
import { ConfigComponentProps } from '../component/nodeConfigurationPage/NodeConfigurationPage';

export const configPages: Record<
  string,
  ComponentType<ConfigComponentProps>
> = {
  'column-filter': ColumnFilterConfig,
};
