import classNames from 'classnames';
import React, { FC, useState, useEffect } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import styles from './styles.module.scss';

export type Props = {
  leftTitle?: string;
  rightTitle?: string;
  leftItems: string[];
  rightItems: string[];
  onChange: (leftItems: string[], rightItems: string[]) => void;
  searchQuery?: string;
  disabled?: boolean;
};

const TransferList: FC<Props> = ({
  leftTitle,
  rightTitle,
  leftItems: initialLeftItems = [],
  rightItems: initialRightItems = [],
  onChange,
  searchQuery,
  disabled,
}) => {
  const [leftItems, setLeftItems] = useState<string[]>(initialLeftItems);
  const [rightItems, setRightItems] = useState<string[]>(initialRightItems);
  const [selectedLeftItems, setSelectedLeftItems] = useState<string[]>([]);
  const [selectedRightItems, setSelectedRightItems] = useState<string[]>([]);

  useEffect(() => {
    setLeftItems(initialLeftItems);
  }, [initialLeftItems]);

  useEffect(() => {
    setRightItems(initialRightItems);
  }, [initialRightItems]);

  const filteredLeftItems = searchQuery
    ? leftItems.filter((item) =>
        item.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : leftItems;

  const filteredRightItems = searchQuery
    ? rightItems.filter((item) =>
        item.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : rightItems;

  const handleLeftItemClick = (item: string, event: React.MouseEvent) => {
    if (event.ctrlKey || event.metaKey) {
      // Toggle selection
      setSelectedLeftItems((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
    } else {
      // Single selection
      setSelectedLeftItems((prev) =>
        prev.includes(item) && prev.length === 1 ? [] : [item]
      );
    }
    // Clear other side selection
    setSelectedRightItems([]);
  };

  const handleRightItemClick = (item: string, event: React.MouseEvent) => {
    if (event.ctrlKey || event.metaKey) {
      // Toggle selection
      setSelectedRightItems((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
    } else {
      // Single selection
      setSelectedRightItems((prev) =>
        prev.includes(item) && prev.length === 1 ? [] : [item]
      );
    }
    // Clear other side selection
    setSelectedLeftItems([]);
  };

  const moveItemsToRight = (items: string[]) => {
    const newLeftItems = leftItems.filter((item) => !items.includes(item));
    const newRightItems = [...rightItems, ...items];
    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
    setSelectedLeftItems([]);
    onChange(newLeftItems, newRightItems);
  };

  const moveItemsToLeft = (items: string[]) => {
    const newRightItems = rightItems.filter((item) => !items.includes(item));
    const newLeftItems = [...leftItems, ...items];
    setRightItems(newRightItems);
    setLeftItems(newLeftItems);
    setSelectedRightItems([]);
    onChange(newLeftItems, newRightItems);
  };

  const moveAllToRight = () => {
    const newRightItems = [...rightItems, ...leftItems];
    setRightItems(newRightItems);
    setLeftItems([]);
    setSelectedLeftItems([]);
    onChange([], newRightItems);
  };

  const moveAllToLeft = () => {
    const newLeftItems = [...leftItems, ...rightItems];
    setLeftItems(newLeftItems);
    setRightItems([]);
    setSelectedRightItems([]);
    onChange(newLeftItems, []);
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // Dropped outside a droppable area
    if (!destination) return;

    const sourceItems =
      source.droppableId === 'left' ? [...leftItems] : [...rightItems];
    const destItems =
      destination.droppableId === 'left' ? [...leftItems] : [...rightItems];

    const [movedItem] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, movedItem);

    if (source.droppableId === 'left') {
      setLeftItems(sourceItems);
    } else {
      setRightItems(sourceItems);
    }

    if (destination.droppableId === 'left') {
      setLeftItems(destItems);
    } else {
      setRightItems(destItems);
    }

    onChange(
      source.droppableId === 'left'
        ? sourceItems
        : destination.droppableId === 'left'
        ? destItems
        : leftItems,
      source.droppableId === 'right'
        ? sourceItems
        : destination.droppableId === 'right'
        ? destItems
        : rightItems
    );
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.disabled]: disabled,
      })}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={styles.column}>
          {leftTitle && <div className={styles.columnHeader}>{leftTitle}</div>}
          <div className={styles.list}>
            <Droppable droppableId='left'>
              {(provided) => (
                <div
                  className={styles.itemsList}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {filteredLeftItems.map((item, index) => (
                    <Draggable
                      key={item}
                      draggableId={`left-${item}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classNames(styles.draggable, {
                            [styles.isDragging]: snapshot.isDragging,
                            [styles.isSelected]:
                              selectedLeftItems.includes(item),
                          })}
                          onClick={(e) => handleLeftItemClick(item, e)}
                          style={provided.draggableProps.style}
                        >
                          {item}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>

        <div className={styles.controls}>
          <button
            className={styles.controlButton}
            onClick={() => moveAllToRight()}
            title='Move all items to right'
          >
            &gt;&gt;
          </button>
          <button
            className={styles.controlButton}
            onClick={() => moveItemsToRight(selectedLeftItems)}
            disabled={selectedLeftItems.length === 0}
            title='Move selected items to right'
          >
            &gt;
          </button>
          <button
            className={styles.controlButton}
            onClick={() => moveItemsToLeft(selectedRightItems)}
            disabled={selectedRightItems.length === 0}
            title='Move selected items to left'
          >
            &lt;
          </button>
          <button
            className={styles.controlButton}
            onClick={() => moveAllToLeft()}
            title='Move all items to left'
          >
            &lt;&lt;
          </button>
        </div>

        <div className={styles.column}>
          {rightTitle && (
            <div className={styles.columnHeader}>{rightTitle}</div>
          )}
          <div className={styles.list}>
            <Droppable droppableId='right'>
              {(provided) => (
                <div
                  className={styles.itemsList}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {filteredRightItems.map((item, index) => (
                    <Draggable
                      key={item}
                      draggableId={`right-${item}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classNames(styles.draggable, {
                            [styles.isDragging]: snapshot.isDragging,
                            [styles.isSelected]:
                              selectedRightItems.includes(item),
                          })}
                          onClick={(e) => handleRightItemClick(item, e)}
                          style={provided.draggableProps.style}
                        >
                          {item}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

export default TransferList;
