import React, { FC } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { MessageDescriptor } from 'react-intl';
import CreatableSelect from 'react-select/creatable';

import styles from './styles.module.scss';
import { FieldInputProps } from '../../molecules/augur-layout-elements/settings-elements/types/type';
import DefaultOptionComponent, {
  DefaultOptionType,
} from '../input-elements/dropdown-select-input/DefaultOptionComponent';
import InputError from '../input-error/InputError';
import IntlWrapper from '../intl-wrapper/IntlWrapper';
import { DeIntl } from '../intl-wrapper/type';
import { customStyles } from '../react-hook-form-input-elements/dropdown-select-input/customStyles';

export interface TextInputLineProps {
  label?: string | MessageDescriptor;
  description?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  wrapMultipleErrorLines?: boolean;
  options: DefaultOptionType[];
  isMulti: boolean;
}

// TODO: Ideally this component should not depend on FieldInputProps (but its rather convenient)
export type Props = TextInputLineProps &
  Partial<FieldInputProps<DefaultOptionType | DefaultOptionType[]>>;

// Don't use this directly, use the intld version
const Combobox: FC<DeIntl<Props>> = (props) => {
  const {
    label,
    description,
    isTouched = false,
    error,
    disabled,
    onChange,
    onBlur,
    name,
    value,
    options,
    wrapMultipleErrorLines,
    isMulti,
  } = props;

  function renderBody() {
    if (!isMulti) {
      return (
        <CreatableSelect
          name={name}
          className={styles.input}
          // @ts-ignore fixme
          styles={customStyles<DefaultOptionType, false>('regular')}
          isClearable={true}
          isDisabled={disabled}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          //@ts-ignore fixme
          components={{ Option: DefaultOptionComponent }}
          options={options}
        />
      );
    }

    return (
      <CreatableSelect
        name={name}
        className={styles.input}
        // @ts-ignore fixme
        styles={customStyles<DefaultOptionType, true>('multiLines')}
        isMulti={isMulti}
        isClearable={true}
        isDisabled={disabled}
        value={value || []}
        onChange={onChange}
        onBlur={onBlur}
        //@ts-ignore fixme
        components={{ Option: DefaultOptionComponent }}
        options={options}
      />
    );
  }

  function renderHeader() {
    return (
      <div className={styles.header}>
        {label && <p className={styles.label}>{label}</p>}
        {description && (
          <MdInfoOutline
            size={17}
            className={styles.description}
            title={description}
          />
        )}
        {error && (
          <div
            className={styles.error}
            style={{
              whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
            }}
          >
            <InputError touched={isTouched} error={error} />
          </div>
        )}
      </div>
    );
  }

  function renderDefaultElement() {
    return (
      <>
        {renderHeader()}
        {renderBody()}
      </>
    );
  }

  return renderDefaultElement();
};

export const IntlCombobox: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={Combobox} props={props} />;
};

export default IntlCombobox;
