import React, { FC } from 'react';

import styles from './styles.module.scss';
import { AS_NODE_STATUS, AsNodeStatus } from './types';
import {
  isPreviewDataTraceback,
  NodeResult,
  useNodeResults,
} from '../../../../../../core/api/flowDesigner';
import { useContent } from '../../../../../../core/api/workbench/content';
import TabLine from '../../../../../molecules/tab-line/TabLine';
import Table from '../../../../../molecules/table/Table';
import { flowNodeLogsPath } from '../utils';

export type Props = {
  selectedNodes: string[];
  filePath: string;
  selectedNodeState?: AsNodeStatus | null;
};

const TablePreview: FC<{ data: Array<Record<string, string>> }> = ({
  data,
}) => {
  const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

  const renderColumns = columnNames.map((columnName) => ({
    key: columnName,
    renderHeader: () => <span>{columnName}</span>,
    renderCell: (col: string) => <span>{col}</span>,
  }));

  return <Table data={data} renderColumns={renderColumns} showHeader />;
};

const TabContent: FC<{ output: unknown }> = ({ output }) => {
  if (output === undefined || output === null) {
    return <p>Not available. Execute node first.</p>;
  }

  // Dynamically infer table structure
  if (
    Array.isArray(output) &&
    output.length > 0 &&
    typeof output[0] === 'object' &&
    !Array.isArray(output[0])
  ) {
    return <TablePreview data={output} />;
  }

  if (
    typeof output === 'string' ||
    typeof output === 'number' ||
    typeof output === 'boolean'
  ) {
    return <pre>{String(output)}</pre>;
  }

  return <pre>{JSON.stringify(output, null, 2)}</pre>;
};

const NodeResultPreview: FC<Props> = ({
  selectedNodes,
  filePath,
  selectedNodeState,
}) => {
  const { data: nodeResults, error } = useNodeResults(
    filePath,
    selectedNodes[0],
    selectedNodes.length === 1
  );

  const { data: selectedNodeLogData } = useContent(
    flowNodeLogsPath(filePath, selectedNodes[0]),
    selectedNodes.length === 1
  );
  const logs = selectedNodeLogData?.content || '';

  if (selectedNodes.length === 0) {
    return <p style={{ padding: '10px' }}>No node selected.</p>;
  } else if (selectedNodes.length > 1) {
    return <p style={{ padding: '10px' }}>Multiple nodes selected.</p>;
  }

  // Only display preview data for nodes with 'success' status
  if (selectedNodeState !== AS_NODE_STATUS.SUCCESS || !nodeResults) {
    return (
      <p style={{ padding: '10px' }}>
        Output not available yet. Execute node first.
      </p>
    );
  }

  if (error) {
    return (
      <p style={{ padding: '10px' }}>Unable to load node content: {error}</p>
    );
  } else if (isPreviewDataTraceback(nodeResults)) {
    return (
      <pre
        style={{ padding: '10px' }}
        className={styles.nodeResultPreviewTraceback}
      >
        {nodeResults.traceback}
      </pre>
    );
  }

  const tabNames: string[] = nodeResults.map(
    (_, index: number) => `Output ${index + 1}`
  );

  const tabContents: JSX.Element[] = nodeResults.map(
    (output: NodeResult, index: number) => (
      <div key={index} style={{ height: '250px', overflow: 'auto' }}>
        <TabContent output={output.data} />
      </div>
    )
  );

  tabNames.push('Logs');
  tabContents.push(
    <div key={'logs'} style={{ height: '250px', overflow: 'auto' }}>
      <pre>{logs}</pre>
    </div>
  );

  return <TabLine tabNames={tabNames} tabContents={tabContents} />;
};

export default NodeResultPreview;
