import { NodeTypes } from '@xyflow/react';

import { ConditionalNode } from './ConditionalNode';
import { CustomPythonNode } from './CustomPythonNode';
import { GatewayNode } from './GatewayNode';
import { MapNode } from './MapNode';
import { SubflowNode } from './SubflowNode';
import { AS_NODE_TYPES } from './types';

export const nodeTypes = {
  [AS_NODE_TYPES.CONDITIONAL]: ConditionalNode,
  [AS_NODE_TYPES.SUBFLOW]: SubflowNode,
  [AS_NODE_TYPES.GATEWAY]: GatewayNode,
  [AS_NODE_TYPES.PYTHON_NODE]: CustomPythonNode,
  [AS_NODE_TYPES.MAP]: MapNode,
} satisfies NodeTypes;
