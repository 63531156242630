import {
  Background,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  getOutgoers,
  Node,
  NodeChange,
  ReactFlow,
  useOnSelectionChange,
  useReactFlow,
} from '@xyflow/react';
import React, { FC, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import '@xyflow/react/dist/base.css';
import { useDrop } from 'react-dnd';

import { AS_DATA_TYPES } from './data.types';
import { edgeTypes } from './edges/types';
import { useFlowDesignerUtils } from './hooks';
import { nodeTypes } from './nodes/nodeTypes';
import { AsNodes, AsNodesWithGateway } from './nodes/types';
import { ToolbarNode } from '../node-repository/NodeRepository';

export type PrototypeProps = {
  filePath: string;
  nodes: AsNodesWithGateway[];
  edges: Edge[];
  onNodesChanged: (changes: NodeChange<AsNodesWithGateway>[]) => void;
  onEdgesChanged: (changes: EdgeChange[]) => void;
  onConnect: (connection: Connection) => void;
  onNodeSelectionChange: (node: string[]) => void;
};

const Prototype: FC<PrototypeProps> = ({
  filePath,
  nodes,
  edges,
  onNodesChanged,
  onEdgesChanged,
  onConnect,
  onNodeSelectionChange,
}) => {
  const { getNodes, getEdges, screenToFlowPosition } = useReactFlow();

  const { addNode } = useFlowDesignerUtils(filePath);

  // CAUTION: This function has to be memoized as described in the reactflow docs: https://reactflow.dev/api-reference/hooks/use-on-selection-change
  //          This is true even though this is just a callback that gets called inside the memoized reactflow onChange function
  // I think this is a bad thing as it contradicts the React docs: https://react.dev/reference/react/memo#usage
  const onChange = useCallback(
    ({ nodes }: { nodes: AsNodesWithGateway[]; edges: Edge[] }) => {
      onNodeSelectionChange(nodes.map((node) => node.id));
    },
    [onNodeSelectionChange]
  );
  useOnSelectionChange({
    onChange,
  });

  const [_, drop] = useDrop({
    accept: 'fdNode',
    drop: (item, monitor) => {
      // @ts-ignore fixme-fd
      const node: ToolbarNode = item.node;

      const clientOffset = monitor.getClientOffset();

      const position = screenToFlowPosition({
        x: clientOffset.x,
        y: clientOffset.y,
      });

      const newNode = {
        id: uuidv4(),
        position,
        type: node.type,
        data: {
          filePath,
          type: node.pythonNodeType,
          name: node.name,
          connections: {
            inputs: node.connections.inputs.map((input) => ({
              id: input.id,
              label: input.defaultLabel,
              typeSchema: input.typeSchema,
              value: input.defaultValue,
              isDynamic: false,
              isFixed: input.isFixed,
              isConfigParameter: input.isConfigParameter,
              typeAttribute: input.typeAttribute,
              isOptional: input.isOptional,
            })),
            outputs: node.connections.outputs.map((input) => ({
              id: input.id,
              label: input.defaultLabel,
              isFixed: input.isFixed,
              typeSchema: input.typeSchema,
            })),
          },
        },
      } as AsNodes;

      addNode(newNode);
    },
  });

  const validNoCycle = useCallback(
    (connection: Connection | Edge) => {
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      if (target === undefined) return true;
      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [getNodes, getEdges]
  );

  return (
    <ReactFlow
      ref={drop}
      isValidConnection={(conn) => {
        // no cycle
        if (!validNoCycle(conn)) return false;
        // not more than one incoming edge
        if (
          edges.find(
            (e) =>
              e.target === conn.target && e.targetHandle === conn.targetHandle
          )
        )
          return false;

        const sourceNode = nodes.find((n) => conn.source === n.id);
        const targetNode = nodes.find((n) => conn.target === n.id);

        const sourceOutputs = sourceNode.data.connections.outputs;
        const sourceType =
          (sourceOutputs.length === 1
            ? sourceOutputs[0].typeSchema?.type
            : sourceOutputs.find((output) => output.id === conn.sourceHandle)
                .typeSchema?.type) ?? AS_DATA_TYPES.ANY;
        const targetInputs = targetNode.data.connections.inputs;
        const targetType =
          (targetInputs.length === 1
            ? targetInputs[0].typeSchema?.type
            : targetInputs.find((output) => output.id === conn.targetHandle)
                .typeSchema?.type) ?? AS_DATA_TYPES.ANY;

        return (
          sourceType === AS_DATA_TYPES.ANY ||
          targetType === AS_DATA_TYPES.ANY ||
          sourceType === targetType
        );
      }}
      nodes={nodes}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChanged}
      edges={edges}
      edgeTypes={edgeTypes}
      onEdgesChange={onEdgesChanged}
      onConnect={onConnect}
      fitView
      proOptions={{ hideAttribution: true }}
    >
      <Background />
      <Controls />
    </ReactFlow>
  );
};

export default Prototype;
