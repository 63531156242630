import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';

export type TabOption = {
  id: string;
  label: string;
};

export type Props = {
  options: TabOption[];
  selected: string;
  onChange: (value: string) => void;
  name?: string;
  className?: string;
};

const TabRadioGroup: FC<Props> = ({
  options,
  selected,
  onChange,
  name = 'tab-radio-group',
  className,
}) => {
  return (
    <div className={classNames(styles.tabRadioContainer, className)}>
      {options.map((option) => {
        const isSelected = selected === option.id;
        const inputId = `${name}-${option.id}`;

        return (
          <label
            key={option.id}
            htmlFor={inputId}
            className={classNames(styles.tabOption, {
              [styles.selected]: isSelected,
            })}
          >
            <input
              id={inputId}
              type='radio'
              name={name}
              className={styles.radioInput}
              checked={isSelected}
              onChange={() => onChange(option.id)}
              value={option.id}
            />
            {option.label}
          </label>
        );
      })}
    </div>
  );
};

export default TabRadioGroup;
