import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  BuildModuleJob,
  BuildModuleJobDefaults,
  ModulesParameters,
  ModuleVersionsParameters,
  ModuleWithAllRelations,
} from 'common/dist/types/module';
import { ModuleVersion } from 'common/dist/types/moduleVersion';
import { AugurReport } from 'common/dist/types/reports';
import { Branch } from 'common/dist/types/repository';
import {
  PostAddModuleBody,
  PutUpdateModuleBody,
} from 'common/dist/types/requestBodies/modules';
import { NamesResponseBody } from 'common/dist/types/responseBodies/base';
import { ResourceNames } from 'common/dist/types/utils';
import { convertTimestamps } from 'common/dist/utils/time';
import qs from 'qs';
import { useSelector } from 'react-redux';

import {
  apiRequest,
  CompletedApiRequest,
  fetchQueryFn,
  fetchQueryFnWorkbench,
  postApiRequest,
  putApiRequest,
} from './_tools';
import { CompletedWorkbenchRequest } from './workbench/_apiRequests';
import NotebookApi from './workbench/git.notebook';
import { BuildModuleFormData } from '../../components/collaborationSpace/wizards/repository-build-module/BuildModuleWizard';
import {
  AugurSettingsWithAugurProperties,
  ModuleConfiguration,
} from '../../components/pages/augur/type';
import { ModuleFormData } from '../../components/pages/NewModuleWizard/NewModuleWizard';
import { notebookUser } from '../../redux/workbench/selectors/notebookUser.selector';
import { getActiveProjectPath } from '../../store/workbench/activeProject.slice';

export const modulesKeys = {
  all: () => ['modules'] as const,
  some: ({
    offset,
    limit,
    search,
    has_repository,
    has_versions,
    created_by,
    is_stock,
  }: ModulesParameters) =>
    [
      ...modulesKeys.all(),
      offset,
      limit,
      search,
      has_repository,
      has_versions,
      created_by,
      is_stock,
    ] as const,
  versions: ({ offset, limit, search, moduleCode }: ModuleVersionsParameters) =>
    [
      ...modulesKeys.all(),
      'versions',
      offset,
      limit,
      search,
      moduleCode,
    ] as const,
  names: () => [...modulesKeys.all(), 'names'] as const,
  creators: () => [...modulesKeys.all(), 'creators'] as const,
  add: () => [...modulesKeys.all(), 'add'] as const,
  edit: (moduleCode: string) =>
    [...modulesKeys.all(), 'edit', moduleCode] as const,
  moduleCode: (moduleCode: string) =>
    [...modulesKeys.all(), 'moduleCode', moduleCode] as const,
  files: (path: string) => [...modulesKeys.all(), 'files', path] as const,
  filesInfinite: (path: string) =>
    [...modulesKeys.all(), 'filesInfinite', path] as const,
  buildModule: (moduleCode: string) =>
    [...modulesKeys.all(), 'buildModule', moduleCode] as const,
  branches: (moduleCode: string) =>
    [...modulesKeys.all(), 'branches', moduleCode] as const,
  removeArtifacts: (path: string) =>
    [...modulesKeys.all(), 'removeArtifacts', path] as const,
};

export function useModules(
  parameters?: ModulesParameters,
  enabled = true
): UseQueryResult<ModuleWithAllRelations[]> {
  const key = modulesKeys.some(parameters || {});
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () => {
        const query = qs.stringify(parameters, { addQueryPrefix: true });
        return apiRequest(`/api/modules${query}`);
      }),
    {
      keepPreviousData: true, // Only use this for paging
      enabled,
    }
  );
}

export function getModuleNames(): CompletedApiRequest<NamesResponseBody> {
  return apiRequest(`/api/modules/names`);
}

export function useModuleNames(): UseQueryResult<ResourceNames> {
  const key = modulesKeys.names();
  return useQuery(key, async () => {
    return fetchQueryFn(key, () => getModuleNames());
  });
}

export function useVersions(
  parameters?: ModuleVersionsParameters,
  enabled = true
): UseQueryResult<ModuleVersion[]> {
  const key = modulesKeys.versions(parameters || {});
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () => {
        const query = qs.stringify(parameters, { addQueryPrefix: true });
        return apiRequest(
          `/api/modules/${parameters.moduleCode}/versions${query}`
        );
      }),
    {
      keepPreviousData: true, // Only use this for paging
      enabled,
    }
  );
}

export function useModuleCreators(): UseQueryResult<string[]> {
  const key = modulesKeys.creators();
  return useQuery(key, () =>
    fetchQueryFn(key, () => {
      return apiRequest(`/api/modules/creators`);
    })
  );
}

export function useModuleByCode(
  moduleCode: string,
  enabled = true
): UseQueryResult<ModuleWithAllRelations, string> {
  const key = modulesKeys.moduleCode(moduleCode);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () => {
        return apiRequest(`/api/modules/${moduleCode}`);
      }),
    {
      enabled,
    }
  );
}

export function useBranchesByModuleCode(
  moduleCode: string,
  enabled = true
): UseQueryResult<Branch[]> {
  const key = modulesKeys.branches(moduleCode);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () => {
        return apiRequest(`/api/modules/${moduleCode}/branches`);
      }),
    {
      enabled,
    }
  );
}

export type DevAugurInfo = {
  config: ModuleConfiguration | null;
  settings: AugurSettingsWithAugurProperties['settingsData'] | null;
  latestReports: {
    learning?: AugurReport;
    evaluation?: AugurReport;
    prediction?: AugurReport;
  };
  reports: AugurReport[];
  dirState: {
    entrypointExists: boolean;
    venvExists: boolean;
  };
  totalReports: number;
};

/**
 *
 * @param path - Relative path from /workbench, no preceding slash
 * @param enabled
 */
export function useModuleFiles(
  path: string,
  enabled = true
): UseQueryResult<DevAugurInfo, string> {
  const key = modulesKeys.files(path);
  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useQuery(
    key,
    () =>
      fetchQueryFnWorkbench(
        key,
        (): CompletedWorkbenchRequest<DevAugurInfo> => {
          return notebookApi.getModuleFiles(path).then((resp) => {
            const parsedResponse = resp?.response;
            convertTimestamps(parsedResponse);
            return {
              ...resp,
              response: parsedResponse,
            };
          });
        }
      ),
    {
      enabled,
    }
  );
}
export function useInfiniteModuleFiles(
  path: string,
  enabled = true,
  limit = 20 // You can adjust this default limit
): UseInfiniteQueryResult<{
  info: DevAugurInfo;
  nextCursor: number | undefined;
}> {
  const key = modulesKeys.filesInfinite(path);
  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useInfiniteQuery(
    key,
    async ({ pageParam = 0 }: { pageParam?: number }) => {
      const reports = (await fetchQueryFnWorkbench(key, () =>
        notebookApi
          .getPagedModuleFiles(path, pageParam, limit + 1)
          .then((resp) => {
            const parsedResponse = resp?.response;
            convertTimestamps(parsedResponse);
            return {
              ...resp,
              response: parsedResponse,
            };
          })
      )) as DevAugurInfo;
      const hasNextPage = reports.reports.length > limit;
      const paginatedJobs = hasNextPage
        ? reports.reports.slice(0, limit)
        : reports.reports;

      return {
        info: { ...reports, reports: paginatedJobs },
        nextCursor: hasNextPage ? pageParam + limit : undefined,
      };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      keepPreviousData: true, // Only use this for paging
      enabled,
    }
  );
}
/**
 * Simplified version of useModuleFiles, if you don't need to change it and don't need the index stuff yourself
 *
 * @returns {
 *     queryResult - the moduleFiles;
 *     relativeModulePath - The path to the module. Relative from the 'notebook_dir': /workbench;
 * }
 */
export function useModuleFilesReadOnly(): {
  queryResult: UseQueryResult<DevAugurInfo>;
  relativeModulePath: string;
} {
  const relativeModulePath = useSelector(getActiveProjectPath);

  return {
    queryResult: useModuleFiles(relativeModulePath),
    relativeModulePath,
  };
}

export function addModule(data: ModuleFormData) {
  const convertedData: PostAddModuleBody = {
    name: data.name,
    slug: data.slug,
    description: data.description,
    moduleAvatar: data.moduleAvatar,
    templateRepoCode: data.templateCode?.value,
  };
  return postApiRequest(`/api/modules`, convertedData);
}

export function useAddModule(): UseMutationResult<
  unknown,
  unknown,
  ModuleFormData
> {
  const queryClient = useQueryClient();
  const key = modulesKeys.add();
  return useMutation(
    key,
    (submitValues: ModuleFormData) =>
      fetchQueryFn(key, () => addModule(submitValues)),
    {
      onSettled: () => {
        // Don't await, because this mutation should just invalidate the queries, not wait until they are fetched again
        void queryClient.invalidateQueries(modulesKeys.all());
      },
    }
  );
}

export function editModule(moduleCode: string, data: ModuleWithAllRelations) {
  //Make sure the object is converted to the PutModuleRequestBody
  const body: PutUpdateModuleBody = {
    moduleAvatar: data.moduleAvatar ?? null,
    description: data.description,
    name: data.name,
  };
  return putApiRequest(`/api/modules/${moduleCode}`, body);
}

export function useEditModule(
  moduleCode: string
): UseMutationResult<unknown, unknown, ModuleWithAllRelations> {
  const queryClient = useQueryClient();
  const key = modulesKeys.edit(moduleCode);
  return useMutation(
    key,
    (submitValues: ModuleWithAllRelations) =>
      fetchQueryFn(key, () => editModule(moduleCode, submitValues)),
    {
      onSettled: () => {
        // Don't await, because this mutation should just invalidate the queries, not wait until they are fetched again
        void queryClient.invalidateQueries(modulesKeys.all());
      },
    }
  );
}

export function buildModule(data: BuildModuleFormData, moduleCode: string) {
  const { repositoryCode, moduleVersionNumber } = data;

  const jobs: BuildModuleJob[] = [
    {
      ...BuildModuleJobDefaults,
      jobCode: 'TMP001',
      repositoryCode: repositoryCode,
      moduleCode: moduleCode,
      moduleVersionNumber: moduleVersionNumber,
    },
  ];
  const jobGroupTopology = [
    {
      jobCode: 'TMP001',
      successors: [],
      predecessors: [],
    },
  ];
  const jobGroup = {
    jobs: jobs,
    jobGroupTopology: jobGroupTopology,
    name: 'Build Module',
    description: 'Build Module',
    trigger: 'manual',
  };
  return postApiRequest(`/orchestration/queue/jobgroup/add`, jobGroup);
}

export function useBuildModule(
  moduleCode: string
): UseMutationResult<unknown, unknown, BuildModuleFormData> {
  const queryClient = useQueryClient();
  const key = modulesKeys.buildModule(moduleCode);
  return useMutation(
    key,
    (data: BuildModuleFormData) =>
      fetchQueryFn(key, () => buildModule(data, moduleCode)),
    {
      onSettled: () => {
        // Don't await, because this mutation should just invalidate the queries, not wait until they are fetched again
        void queryClient.invalidateQueries(modulesKeys.all());
      },
    }
  );
}
