import { Node, NodeProps } from '@xyflow/react';
import React from 'react';

import GenericNode from './GenericNode';
import { getNodeIcon } from './nodeIcons';
import {
  AS_NODE_STATUS,
  AS_NODE_TYPES,
  AsNodesWithGateway,
  GenericNodeData,
} from './types';
import {
  generateReturnValues,
  getIncomingVarNameByHandleId,
  subflowFn,
  varname,
} from '../codegen';
import { useFlowDesignerUtils } from '../hooks';
import { GenerationProps } from '../types';

export type SubflowNodeData = GenericNodeData & {
  subflowId: string;
};

export type SubflowNode = Node<SubflowNodeData, typeof AS_NODE_TYPES.SUBFLOW>;

export function isSubflowNode(node: AsNodesWithGateway): node is SubflowNode {
  return node.type === AS_NODE_TYPES.SUBFLOW;
}

/**
 * Generate the code part that executes a subflow. This does not include the definition
 * @param context
 * @param node
 * @param flow
 * @param localStatePath
 */
export const generateSubflowCode = ({
  context,
  node,
  flow,
  localStatePath,
}: GenerationProps<SubflowNode>): string[] => {
  const subflow = flow.subflows.find(
    (subflow) => subflow.id === node.data.subflowId
  );
  const subflowName = subflowFn(context, subflow);

  const inputVars = node.data.connections.inputs
    .map((input) => getIncomingVarNameByHandleId(context, flow, node, input.id))
    .join(', ');
  const outputVars: string | null = generateReturnValues(context, node);
  const outputIdsStr = node.data.connections.outputs
    .map((output) => `'${output.id}'`)
    .join(', ');

  // Need to set variables to none, so that the entryAllowed checks work
  const returnValueNames = node.data.connections.outputs.map((output) =>
    varname(context, node, output.id)
  );

  // Initialize return variables with None
  const initializeVariables =
    returnValueNames.length > 0
      ? `${returnValueNames.join(', ')} = ${returnValueNames
          .map((_) => 'None')
          .join(', ')}`
      : '';

  // bool to check if any params are None
  const entryAllowed = `all([param is not None for param in [${node.data.connections.inputs
    .map((input) => getIncomingVarNameByHandleId(context, flow, node, input.id))
    .join(',')}]])`;

  const functionCall = !outputVars
    ? `${subflowName}(${inputVars})`
    : `${outputVars} = ${subflowName}(${inputVars})`;

  // bool to check if no return values are none
  const allSuccessful = `all([param is not None for param in [${returnValueNames.join(
    ','
  )}]])`;

  // Get all node IDs in the subflow to check their states
  const subflowNodeIds = subflow?.nodes?.map((n) => n.id) || [];
  const subflowNodeIdsStr = JSON.stringify(subflowNodeIds);

  const saveResults = `save_var_to_results('${
    node.id
  }', zip([${outputIdsStr}], [${returnValueNames.join(', ')}]))`;

  return `
### Executing Subflow "${subflowName}" ###
${initializeVariables}
if ${entryAllowed}:
  update_state('${node.id}', '${AS_NODE_STATUS.RUNNING}')
  try:
    with tee_output('${node.id}'):
      ${functionCall}
    
    # Check if any nodes inside the subflow are in error state
    has_error_nodes = False
    try:
      with open('${localStatePath}', 'r') as f:
        state_data = json.load(f)
              
      # Check each node in the subflow
      subflow_node_ids = ${subflowNodeIdsStr}
      for node_id in subflow_node_ids:
        if node_id in state_data and state_data[node_id] == '${AS_NODE_STATUS.ERROR}':
          has_error_nodes = True
          break
    except Exception:
      pass
    
    if has_error_nodes:
      # Set subflow node to ERROR state if any node inside the subflow is in ERROR state
      update_state('${node.id}', '${AS_NODE_STATUS.ERROR}')
    elif ${allSuccessful}:
      update_state('${node.id}', '${AS_NODE_STATUS.SUCCESS}')
      ${saveResults}
  
  except Exception:
    update_state('${node.id}', '${AS_NODE_STATUS.ERROR}')
    save_stacktrace_to_results('${node.id}', traceback.format_exc())
`.split('\n');
};

export function SubflowNode({ id, type, data }: NodeProps<SubflowNode>) {
  const { filePath, subflowId } = data;

  const { openSubflow } = useFlowDesignerUtils(filePath);

  return (
    <GenericNode
      id={id}
      name={'Subflow'}
      Icon={getNodeIcon(type)}
      onClick={() => openSubflow(subflowId)}
      {...data}
    />
  );
}
