import { CustomPythonNodeDefinition } from './customNodes.types';
import {
  CONDITIONAL_INPUTS,
  CONDITIONAL_OUTPUTS,
  ConditionalNode,
} from './nodes/ConditionalNode';
import { CustomPythonNode } from './nodes/CustomPythonNode';
import { GatewayNode } from './nodes/GatewayNode';
import { MapNode } from './nodes/MapNode';
import { SubflowNode } from './nodes/SubflowNode';
import { AS_NODE_TYPES, AsNodesWithGateway } from './nodes/types';
import {
  FlowConfig,
  PersistedConditionalNode,
  PersistedCustomPythonNode,
  PersistedEdge,
  PersistedGatewayNode,
  PersistedMapNode,
  PersistedNode,
  PersistedSubflowNode,
} from './persisted.types';
import {
  BasePersistedParameter,
  FlowData,
  PersistedInputParameter,
} from './types';

export function toPersisted(node: AsNodesWithGateway): PersistedNode {
  switch (node.type) {
    case AS_NODE_TYPES.PYTHON_NODE: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          name: node.data.name,
          type: node.data.type,
          connections: {
            inputs: node.data.connections.inputs.map(
              (input) =>
                ({
                  id: input.id,
                  label: input.label,
                  isDynamic: input.isDynamic,
                  value: input.value,
                } satisfies PersistedInputParameter)
            ),
            outputs: node.data.connections.outputs.map(
              (output) =>
                ({
                  id: output.id,
                  label: output.label,
                } satisfies BasePersistedParameter)
            ),
          },
        },
      } satisfies PersistedCustomPythonNode;
    }
    case AS_NODE_TYPES.SUBFLOW: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          name: node.data.name,
          subflowId: node.data.subflowId,
          connections: node.data.connections,
        },
      } satisfies PersistedSubflowNode;
    }
    case AS_NODE_TYPES.MAP: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          name: node.data.name,
          subflowId: node.data.subflowId,
          connections: node.data.connections,
        },
      } satisfies PersistedMapNode;
    }
    case AS_NODE_TYPES.GATEWAY: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          name: node.data.name,
          connections: node.data.connections,
          gatewayType: node.data.gatewayType,
          editable: node.data.editable,
        },
      } satisfies PersistedGatewayNode;
    }
    case AS_NODE_TYPES.CONDITIONAL: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          name: node.data.name,
          connections: node.data.connections,
        },
      } satisfies PersistedConditionalNode;
    }
  }
}

export function fromPersisted(
  node: PersistedNode,
  filePath: string,
  nodeDefinitions: CustomPythonNodeDefinition[]
): AsNodesWithGateway {
  switch (node.type) {
    case AS_NODE_TYPES.PYTHON_NODE: {
      // If any node in a flow is not found here, the whole flow is invalid!
      // TODO Perhaps we could return some kind of "invalid node" marker to make debugging easier?
      //  https://gitlab.sigmalto.com/altasigma-platform/concept-flow-designer/-/issues/105
      const nodeDefinition = nodeDefinitions.find(
        (nd) => nd.type === node.config.type
      );
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          ...node.config,
          filePath,
          connections: {
            inputs: nodeDefinition.connections.inputs.map((input) => {
              const persistedInput = node.config.connections?.inputs.find(
                (i) => i.id === input.id
              );
              return {
                ...input,
                label: persistedInput?.label,
                value: persistedInput?.value,
                isDynamic: persistedInput?.isDynamic,
              };
            }),
            outputs: nodeDefinition.connections.outputs.map((output) => {
              const persistedOutput = node.config.connections?.outputs.find(
                (o) => o.id === output.id
              );
              return {
                ...output,
                label: persistedOutput?.label,
              };
            }),
          },
          name: nodeDefinition.title,
        },
      } satisfies CustomPythonNode;
    }
    case AS_NODE_TYPES.CONDITIONAL: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          connections: {
            inputs: CONDITIONAL_INPUTS,
            outputs: CONDITIONAL_OUTPUTS,
          },
        },
      } satisfies ConditionalNode;
    }
    case AS_NODE_TYPES.SUBFLOW: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          ...node.config,
        },
      } satisfies SubflowNode;
    }
    case AS_NODE_TYPES.MAP: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          ...node.config,
        },
      } satisfies MapNode;
    }
    case AS_NODE_TYPES.GATEWAY: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          ...node.config,
        },
      } satisfies GatewayNode;
    }
  }
}

export const flowDataToFlowConfig = (flow: FlowData): FlowConfig => ({
  ...flow,
  nodes: flow.nodes.map((node) => toPersisted(node)),
  edges: flow.edges.map(
    (edge) =>
      ({
        id: edge.id,
        type: edge.type,
        target: edge.target,
        source: edge.source,
        targetHandle: edge.targetHandle,
        sourceHandle: edge.sourceHandle,
      } satisfies PersistedEdge)
  ),
  subflows: flow.subflows.map((flow) => flowDataToFlowConfig(flow)),
});

export function flowConfigToFlowData(
  flowConfig: FlowConfig,
  filePath: string,
  nodeDefinitions: CustomPythonNodeDefinition[]
): FlowData {
  return {
    ...flowConfig,
    nodes: flowConfig.nodes.map((node) =>
      fromPersisted(node, filePath, nodeDefinitions)
    ),
    subflows: flowConfig.subflows.map((subflow) =>
      flowConfigToFlowData(subflow, filePath, nodeDefinitions)
    ),
  };
}
