/**
 * Possible connection/handle data types.
 */
export const AS_DATA_TYPES = {
  ANY: 'any',
  TABLE: 'table',
  BOOL: 'bool',
  NUMBER: 'number',
  STRING: 'string',
  LIST: 'list',
} as const;
export type AsDataTypes = typeof AS_DATA_TYPES[keyof typeof AS_DATA_TYPES];

export type AsSimpleTypeSchema = {
  type: Exclude<
    AsDataTypes,
    typeof AS_DATA_TYPES.TABLE | typeof AS_DATA_TYPES.LIST
  >;
};

export type AsTableTypeSchema = {
  type: typeof AS_DATA_TYPES.TABLE;
  properties: {
    [key: string]: AsDataTypes;
  };
};

export type AsListTypeSchema = {
  type: typeof AS_DATA_TYPES.LIST;
  items: AsDataTypeSchema;
};

export type AsDataTypeSchema =
  | AsSimpleTypeSchema
  | AsTableTypeSchema
  | AsListTypeSchema;
