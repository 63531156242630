import { useEdges, useNodesData } from '@xyflow/react';
import React, { FC, PropsWithChildren } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { MultiValue } from 'react-select';

import styles from './styles.module.scss';
import { useMultipleNodeResults } from '../../../../../../core/api/flowDesigner';
import { useAppDispatch } from '../../../../../../store/store';
import {
  configValueChanged,
  dynamicEdgeChanged,
} from '../../../../../../store/workbench/flowDesigner.slice';
import Combobox from '../../../../../atoms/combobox/Combobox';
import { DefaultOptionType } from '../../../../../atoms/input-elements/dropdown-select-input/DefaultOptionComponent';
import { Checkbox } from '../../../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import IntlTextInputLine from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import ThemedSwitch from '../../../../../atoms/themed-switch/ThemedSwitch';
import { configPages } from '../../configuration-pages/configPages';
import { CustomPythonNodeData } from '../nodes/CustomPythonNode';
import { AS_NODE_TYPES, AsNodesWithGateway } from '../nodes/types';
import { InputParameter } from '../types';

export type Props = {
  filePath: string;
  selectedFlowPath: string[];
  selectedNodeId: string;
};

export type ElementProps = {
  parameter: InputParameter;
  configOptions: unknown[];
  onValueChange: (parameterId: string, value: unknown) => void;
  onDynamicChange: (parameterId: string, isDynamic: boolean) => void;
};

export type ConfigComponentProps<
  TAttributes extends Record<string, unknown[]> = Record<string, unknown[]>
> = {
  onValueChange: (parameterId: string, value: unknown) => void;
  onDynamicChange: (parameterId: string, isDynamic: boolean) => void;
  // lookup map for options that come from type attributes of another input
  configOptions?: TAttributes;
};

export type ConfigFormTransform<
  T extends Record<string, unknown> = Record<string, unknown>
> = {
  [K in keyof T as `${string & K}.isDynamic`]: boolean;
} & {
  [K in keyof T as `${string & K}.value`]: T[K];
};

// This pattern covers all Python number types in JS-compatible regex form
const PYTHON_NUMBER_PATTERN =
  /^[-+]?(?:\d+(?:\.\d*)?|\.\d+)(?:[eE][-+]?\d+)?(?:j)?$|^[-+]?0[xXbBoO][0-9a-fA-F]+$/;

export type GenericConfigParameterProps = {
  parameterId: string;
  onDynamicChange: (parameterId: string, isDynamic: boolean) => void;
};

export const GenericConfigParameter: FC<
  PropsWithChildren<GenericConfigParameterProps>
> = ({ parameterId, onDynamicChange, children }) => {
  const { control } = useFormContext<ConfigFormTransform>();

  return (
    <div key={parameterId} className={styles.entry}>
      <div className={styles.checkbox}>
        <Controller
          name={`${parameterId}.isDynamic`}
          control={control}
          render={({ field }) => {
            const { ref, name, value, onChange } = field;
            return (
              <Checkbox
                inputRef={ref}
                name={name}
                checked={value}
                onChange={(isDynamic) => {
                  onChange?.(isDynamic);
                  onDynamicChange(parameterId, isDynamic);
                }}
              />
            );
          }}
        />
      </div>
      <div className={styles.inputField}>{children}</div>
    </div>
  );
};

const NodeConfigurationElement: FC<ElementProps> = (props) => {
  const { parameter, configOptions, onValueChange, onDynamicChange } = props;

  const { control, getValues } = useFormContext<ConfigFormTransform>();
  const isDisabled = getValues(`${parameter.id}.isDynamic`);

  function renderInputElement() {
    switch (parameter.typeSchema?.type) {
      case 'number': {
        return (
          <Controller
            name={`${parameter.id}.value`}
            control={control}
            disabled={isDisabled}
            rules={{
              pattern: {
                value: PYTHON_NUMBER_PATTERN,
                message: 'Please enter a valid Python number.',
              },
            }}
            render={({ field, fieldState }) => {
              const { ref, onChange, value, ...rest } = field;
              const { error, ...restFieldState } = fieldState;
              return (
                <IntlTextInputLine
                  inputRef={ref}
                  label={parameter.label}
                  value={String(value ?? '')}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange?.(value);
                    onValueChange(parameter.id, value);
                  }}
                  error={error?.message}
                  {...rest}
                  {...restFieldState}
                  isTouched={true} // immediately show an error
                />
              );
            }}
          />
        );
      }
      case 'bool': {
        return (
          <Controller
            name={`${parameter.id}.value`}
            control={control}
            disabled={isDisabled}
            render={({ field, fieldState }) => {
              const { ref, onChange, value, ...rest } = field;
              const { error, ...restFieldState } = fieldState;
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ alignContent: 'center', color: 'gray' }}>
                    {parameter.label}
                  </div>
                  <ThemedSwitch
                    checked={Boolean(value ?? '')}
                    onChange={(checked) => {
                      onChange?.(checked);
                      onValueChange(parameter.id, checked);
                    }}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    {...rest}
                    {...restFieldState}
                  />
                </div>
              );
            }}
          />
        );
      }
      case 'string': {
        return (
          <Controller
            name={`${parameter.id}.value`}
            control={control}
            disabled={isDisabled}
            render={({ field, fieldState }) => {
              const { ref, value, onChange, ...rest } = field;
              const { error, ...restFieldState } = fieldState;

              const options = configOptions.map(
                (attributeValue) =>
                  ({
                    label: String(attributeValue),
                    value: attributeValue as string,
                  } satisfies DefaultOptionType)
              );

              return (
                <IntlTextInputLine
                  inputRef={ref}
                  label={parameter.label}
                  value={String(value ?? '')}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange?.(value);
                    onValueChange(parameter.id, value);
                  }}
                  error={error?.message}
                  options={options}
                  {...rest}
                  {...restFieldState}
                />
              );
            }}
          />
        );
      }
      case 'list': {
        return (
          <Controller
            name={`${parameter.id}.value`}
            control={control}
            disabled={isDisabled}
            render={({ field, fieldState }) => {
              const { ref, value, onChange, ...rest } = field;
              const { error, ...restFieldState } = fieldState;

              const options = configOptions.map(
                (attributeValue) =>
                  ({
                    label: String(attributeValue),
                    value: attributeValue,
                  } satisfies DefaultOptionType)
              );

              const optionValue = (value as unknown[])?.map(
                (listValue) =>
                  ({
                    label: String(listValue),
                    value: listValue,
                  } satisfies DefaultOptionType)
              );

              return (
                <Combobox
                  {...rest}
                  {...restFieldState}
                  label={parameter.label}
                  error={error?.message}
                  value={optionValue}
                  isMulti={true}
                  onChange={(selected) => {
                    const newValue = (
                      selected as MultiValue<DefaultOptionType>
                    ).map((option) => option.value);
                    onChange?.(newValue);
                    onValueChange(parameter.id, newValue);
                  }}
                  options={options}
                />
              );
            }}
          />
        );
      }
    }
  }

  return (
    <GenericConfigParameter
      parameterId={parameter.id}
      onDynamicChange={onDynamicChange}
    >
      {renderInputElement()}
    </GenericConfigParameter>
  );
};

const GenericNodeConfigurationPage: FC<
  ConfigComponentProps & {
    configParameters: InputParameter[];
  }
> = (props) => {
  const { configParameters, configOptions, onDynamicChange, onValueChange } =
    props;

  return (
    <>
      {configParameters.map((parameter) => {
        return (
          <NodeConfigurationElement
            key={parameter.id}
            parameter={parameter}
            configOptions={configOptions[parameter.id] || []}
            onDynamicChange={onDynamicChange}
            onValueChange={onValueChange}
          />
        );
      })}
    </>
  );
};

const NodeConfigurationPage: FC<Props> = (props) => {
  const { selectedNodeId, selectedFlowPath, filePath } = props;

  const dispatch = useAppDispatch();

  const selectedNodesData = useNodesData<AsNodesWithGateway>(selectedNodeId);
  const selectedNodesType =
    selectedNodesData?.type === AS_NODE_TYPES.PYTHON_NODE
      ? (selectedNodesData?.data as CustomPythonNodeData).type
      : selectedNodesData?.type;
  const inputs = selectedNodesData?.data.connections.inputs || [];
  const configParameters = inputs?.filter((param) => param.isConfigParameter);

  const defaultValues = Object.fromEntries(
    configParameters.flatMap((param) => {
      return [
        [`${param.id}.value`, param.value],
        [`${param.id}.isDynamic`, param.isDynamic],
      ];
    })
  );

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const edges = useEdges();

  // find all parameters that have a typeAttribute reference
  const parametersWithTypeAttributes = configParameters.filter(
    (parameter) => !!parameter.typeAttribute
  );
  // get all source nodes of parameters with type attributes
  const attributeSourceNodes = parametersWithTypeAttributes.map(
    (configAttribute) => {
      const edge = edges.find(
        (edge) =>
          edge.target === selectedNodeId &&
          edge.targetHandle === configAttribute.typeAttribute?.inputKey
      );
      return edge?.source;
    }
  );
  // get the node results for all source nodes
  const nodeResults = useMultipleNodeResults(filePath, attributeSourceNodes);
  const nodeResultsData = nodeResults.map((result) => result.data);

  // construct a lookup map [(id of parameter with type attribute), type attribute]
  const attributes = Object.fromEntries(
    parametersWithTypeAttributes.map((parameter) => {
      // find the connecting edge with the inputKey
      const edge = edges.find(
        (edge) =>
          edge.target === selectedNodeId &&
          edge.targetHandle === parameter.typeAttribute?.inputKey
      );
      // get the source node results for the node the edge is connected to
      const sourceNodeResults = nodeResultsData.find(
        (res) => res?.[0] === edge?.source
      )?.[1];
      // get the result for the output the edge is connected to
      const sourceNodeEdgeResult = sourceNodeResults?.find(
        (result) => result.id === edge?.sourceHandle
      );
      // extract the attribute by attributeKey
      const attributeValues: unknown[] =
        sourceNodeEdgeResult?.attributes?.[
          parameter.typeAttribute?.attributeKey
        ] ?? [];

      return [parameter.id, attributeValues];
    })
  );

  function handleValueChange(parameterId: string, value: unknown) {
    dispatch(
      configValueChanged({
        filePath,
        selectedFlowPath,
        nodeId: selectedNodeId,
        parameterId,
        value,
      })
    );
  }

  function handleDynamicChange(parameterId: string, isDynamic: boolean) {
    dispatch(
      dynamicEdgeChanged({
        filePath,
        selectedFlowPath,
        nodeId: selectedNodeId,
        parameterId,
        isDynamic,
      })
    );
  }

  function renderComponent() {
    const Component = configPages[selectedNodesType];
    if (Component) {
      return (
        <Component
          configOptions={attributes}
          onDynamicChange={handleDynamicChange}
          onValueChange={handleValueChange}
        />
      );
    } else {
      return (
        <GenericNodeConfigurationPage
          configParameters={configParameters}
          configOptions={attributes}
          onDynamicChange={handleDynamicChange}
          onValueChange={handleValueChange}
        />
      );
    }
  }

  return (
    <FormProvider {...methods}>
      <div className={styles.container}>
        {configParameters.length === 0 && (
          <div>No configuration options for the selected element.</div>
        )}
        {renderComponent()}
      </div>
    </FormProvider>
  );
};

export default NodeConfigurationPage;
