import { Node, NodeProps } from '@xyflow/react';
import React from 'react';

import GenericNode from './GenericNode';
import { getNodeIcon } from './nodeIcons';
import {
  AS_NODE_STATUS,
  AS_NODE_TYPES,
  AsNodesWithGateway,
  GenericNodeData,
} from './types';
import { getIncomingVarNameByHandleId, nodeFn, varname } from '../codegen';
import { GenerationProps } from '../types';

export type CustomPythonNodeData = {
  type: string;
} & GenericNodeData;

export type CustomPythonNode = Node<
  CustomPythonNodeData,
  typeof AS_NODE_TYPES.PYTHON_NODE
>;

export function isCustomPythonNode(
  node: AsNodesWithGateway
): node is CustomPythonNode {
  return node.type === AS_NODE_TYPES.PYTHON_NODE;
}

export const generatePythonFunctionNodeCode = ({
  context,
  node,
  flow,
}: GenerationProps<CustomPythonNode>): string[] => {
  const paramNames = node.data.connections.inputs.map((input) =>
    getIncomingVarNameByHandleId(context, flow, node, input.id)
  );
  const returnValueNames = node.data.connections.outputs.map((output) =>
    varname(context, node, output.id)
  );
  const fnName = nodeFn(node.data.type);
  const outputIdsStr = node.data.connections.outputs
    .map((output) => `'${output.id}'`)
    .join(', ');

  const parameters = `${paramNames.join(', ')}`;

  let functionCall: string;
  if (returnValueNames.length === 0) {
    functionCall = `${fnName}(${parameters})`;
  } else {
    functionCall = `${returnValueNames.join(', ')} = ${fnName}(${parameters})`;
  }

  // Need to set variables to none, so that the entryAllowed checks work
  const initializeVariables =
    returnValueNames.length > 0
      ? `${returnValueNames.join(', ')} = ${returnValueNames
          .map((_) => 'None')
          .join(', ')}`
      : '';

  const entryAllowed = `all([param is not None for param in [${paramNames.join(
    ','
  )}]])`;

  const saveResults = `save_var_to_results('${
    node.id
  }', zip([${outputIdsStr}], [${returnValueNames.join(', ')}]))`;

  return `
##########################################  
### Executing Node "${node.data.name}" ###
##########################################
${initializeVariables}
if ${entryAllowed}:
  update_state('${node.id}', '${AS_NODE_STATUS.RUNNING}')
  try:
    with tee_output('${node.id}'):
      ${functionCall}
    update_state('${node.id}', '${AS_NODE_STATUS.SUCCESS}')
    ${saveResults}
  except Exception:
    update_state('${node.id}', '${AS_NODE_STATUS.ERROR}')
    save_stacktrace_to_results('${node.id}', traceback.format_exc())
`.split('\n');
};

export function CustomPythonNode({
  id,
  type,
  data,
}: NodeProps<CustomPythonNode>) {
  const { name, type: subtype } = data;

  return (
    <GenericNode
      id={id}
      name={name}
      Icon={getNodeIcon(type, subtype)}
      {...data}
    />
  );
}
