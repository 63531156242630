import { CassandraSettings } from 'common/dist/types/dataManagement/dataSource';
import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';
import './StepSettings.scss';

export type Value = CassandraSettings;
export type Error = string;
export type Props = {
  input: {
    value: Value;
    onBlur: (v: Value) => ToBeRefined;
    onChange: (v: Value) => ToBeRefined;
  };
  valid: boolean;
  error: Error;
  touched: boolean;
};

export default class StepCassandraSettings extends Component<
  Props & WrappedFieldProps
> {
  render() {
    const {
      meta: { error, touched },
      input: { value, onChange, onBlur },
    } = this.props;
    const { host, port, user, password, datacenter } = value;

    return (
      <div className={'input-container'}>
        {touched && error && (
          <div className={'error-container'}>
            <p className={'error'}>{error}</p>
          </div>
        )}
        <div className={'input'}>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Host</span>
            </label>
            <input
              data-testid={'Host'}
              type={'text'}
              value={host || ''}
              onChange={(e) => onChange({ ...value, host: e.target.value })}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Port</span>
            </label>
            <input
              data-testid={'Port'}
              type={'text'}
              value={port || ''}
              onChange={(e) => onChange({ ...value, port: e.target.value })}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>User</span>
            </label>
            <input
              data-testid={'User'}
              type={'text'}
              value={user || ''}
              onChange={(e) => onChange({ ...value, user: e.target.value })}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Password</span>
            </label>
            <input
              data-testid={'Password'}
              type={'text'}
              value={password || ''}
              onChange={(e) => onChange({ ...value, password: e.target.value })}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Datacenter</span>
            </label>
            <input
              data-testid={'Datacenter'}
              type={'text'}
              value={datacenter || ''}
              onChange={(e) =>
                onChange({ ...value, datacenter: e.target.value })
              }
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}
