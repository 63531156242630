/**
 * Generates a python helper function for saving node results
 * @param localResultsDir
 */
export function getSaveResultsHelperFunction(localResultsDir: string): string {
  return `import json, os, pandas as pd
def save_var_to_results(node_id, outputs):
      os.makedirs('${localResultsDir}', exist_ok=True)
      
      results = []
      for id, value in outputs:
        if isinstance(value, pd.DataFrame):
          result = {
            "id": id,
            "data": value.to_dict(orient='records'),
            "attributes": {
            "columns": value.columns.tolist()
            }
          }
        elif isinstance(value, (bool, int, float, str)):
          result = {
            "id": id,
            "data": value
          }
        else:
          result = {
            "id": id,
            "data": repr(value)
          }
        results.append(result)      
      
      with open(f'${localResultsDir}/{node_id}.json', 'w') as file:
        json.dump(results, file)

import traceback
def save_stacktrace_to_results(node_id, traceback_str):
      os.makedirs('${localResultsDir}', exist_ok=True)
      
      with open(f'${localResultsDir}/{node_id}.json', 'w') as file:
        json.dump({"traceback": traceback_str}, file)
    `;
}

/**
 * Generates a python helper function for updating node states
 * @param localResultsDir
 * @param localStatePath
 */
export function getUpdateStateHelperFunction(
  localResultsDir: string,
  localStatePath: string
): string {
  return `def update_state(var_name, value):
    import json, os
    os.makedirs('${localResultsDir}', exist_ok=True)
    # Path to the state file
    state_file = '${localStatePath}'
    
    # Initialize state dictionary
    state = {}
    
    # Try to read existing state file if it exists
    if os.path.exists(state_file):
        try:
            with open(state_file, 'r') as file:
                state = json.load(file)
        except json.JSONDecodeError:
            # If file exists but is not valid JSON, start with empty dict
            state = {}
    
    # Update state with new key-value pair
    state[var_name] = value
    
    # Write updated state back to file
    with open(state_file, 'w') as file:
        json.dump(state, file)

`;
}

/**
 * Generates a python helper function for node-based logging
 * @param localResultsDir
 */
export function getLoggingHelperFunction(localResultsDir: string): string {
  return `
import sys
from contextlib import contextmanager

@contextmanager
def tee_output(node_id):
    # Save original stdout and stderr
    original_stdout = sys.stdout
    original_stderr = sys.stderr
    
    # Open single file for both streams
    file = open(f'${localResultsDir}/{node_id}.log', 'w')
    
    class TeeWriter:
        def __init__(self, original_stream):
            self.original_stream = original_stream
            
        def write(self, text):
            self.original_stream.write(text)
            file.write(text)
            
        def flush(self):
            self.original_stream.flush()
            file.flush()
    
    # Replace both stdout and stderr with TeeWriter instances
    sys.stdout = TeeWriter(original_stdout)
    sys.stderr = TeeWriter(original_stderr)
    
    try:
        yield
    finally:
        # Restore original streams
        sys.stdout = original_stdout
        sys.stderr = original_stderr
        file.close()
`;
}
