import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import classNames from 'classnames';
import { GroupType } from 'common/dist/types/pipeline';
import React, { FC } from 'react';

import { NodeContent } from './FlowElementNode';
import { PipelineTuningNode } from './types';

export type GroupData = GroupType;
export type FlowElementGroup = Node<GroupData, 'group'>;

export function isGroupNode(
  node: PipelineTuningNode
): node is FlowElementGroup {
  return node.type === 'group';
}

export const FlowElementGroup: FC<NodeProps<FlowElementGroup>> = (props) => {
  const { data } = props;
  return (
    <div className={classNames('PipelineTuningChart--flow-element-group')}>
      <Handle type={'target'} position={Position.Left} />
      <GroupContent {...data} />
      <Handle type={'source'} position={Position.Right} />
    </div>
  );
};

const GroupContent: FC<NodeProps<FlowElementGroup>['data']> = (data) => {
  return (
    <div className={'PipelineTuningChart--group'}>
      <div className={'PipelineTuningChart--group-title-container'}>
        <span className={'PipelineTuningChart--group-title'}>
          {data.displayName}
        </span>
      </div>
      {data?.nodes.map((node) => (
        <div
          key={node.id}
          className={classNames(
            'PipelineTuningChart--group-node',
            {
              'PipelineTuningChart--group-node-selected':
                data.selectedNodeId === node.id,
            },
            {
              'PipelineTuningChart--group-node-type-classifier':
                node.nodeType === 'classifier',
            },
            {
              'PipelineTuningChart--group-node-type-transformer':
                node.nodeType === 'transformer',
            }
          )}
          onClick={() => data.setSelectedNode && data.setSelectedNode(node)}
        >
          <NodeContent {...node} />
        </div>
      ))}
    </div>
  );
};
