import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { isApiError } from 'common/dist/constants/errors';
import { useSelector } from 'react-redux';

import { contentKeys, fetchContent } from './workbench/content';
import { flowNodeResultPath } from '../../components/workbench/fileTypes/flowDesigner/component/utils';
import { notebookUser } from '../../redux/workbench/selectors/notebookUser.selector';
import { JupyterContentElement } from '../../store/workbench/state.types';

export type NodeResult = {
  id: string;
  data: unknown;
  attributes?: {
    columns: string[];
    types: unknown;
  };
};

/**
 * Saved from the python code for a node which threw an exception
 */
type PreviewDataTraceback = {
  /** formatted python traceback by traceback.format_exc(). Includes \n and significatn whitespace */
  traceback: string;
};

export function isPreviewDataTraceback(
  result: NodeResult[] | PreviewDataTraceback
): result is PreviewDataTraceback {
  return !Array.isArray(result) && !!result.traceback;
}

export function isNodeResultsData(
  result: NodeResult[] | PreviewDataTraceback
): result is NodeResult[] {
  return Array.isArray(result);
}

async function fetchNodeResultContent<T>(
  jupyterUser: string,
  path: string
): Promise<T> {
  const key = contentKeys.content(jupyterUser, path);

  const { response, error } = await fetchContent(jupyterUser, path);

  if (error) {
    if (isApiError(error)) {
      console.error(`Unable to fetch [${key}]: ${JSON.stringify(error)}`);
      throw error;
    }
    throw Error(`Unable to fetch [${key}]: ${JSON.stringify(error)}`);
  }

  if (!response) {
    throw Error(`No response received for [${key}]`);
  }

  try {
    const contentElement = response as JupyterContentElement;
    return JSON.parse(contentElement.content);
  } catch (error) {
    throw Error(`Failed to parse JSON result at path ${path}`);
  }
}

/**
 * Hook to fetch results for multiple nodes in a flow
 */
export function useMultipleNodeResults(
  flowPath: string,
  nodeIds: string[]
): UseQueryResult<[string, NodeResult[]]>[] {
  const jupyterUser: string = useSelector((state) => notebookUser(state));

  return useQueries({
    queries: nodeIds.map((nodeId) => {
      const path = flowNodeResultPath(flowPath, nodeId);
      const key = contentKeys.content(jupyterUser, path);

      return {
        queryKey: key,
        queryFn: async () => {
          const results = await fetchNodeResultContent<NodeResult[]>(
            jupyterUser,
            path
          );
          return [nodeId, results] as [string, NodeResult[]];
        },
      };
    }),
  });
}

/**
 * Hook to fetch results for a single node in a flow
 */
export function useNodeResults(
  flowPath: string,
  nodeId?: string,
  enabled?: boolean
): UseQueryResult<NodeResult[] | PreviewDataTraceback, string> {
  const jupyterUser: string = useSelector((state) => notebookUser(state));
  const path = flowNodeResultPath(flowPath, nodeId);
  const key = contentKeys.content(jupyterUser, path);

  return useQuery(
    key,
    async () => {
      const results = await fetchNodeResultContent<NodeResult[]>(
        jupyterUser,
        path
      );

      if (!isNodeResultsData(results)) {
        console.warn('Found unexpected json format:', results);
        return undefined;
      }

      return results;
    },
    { enabled }
  );
}
