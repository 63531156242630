import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { MdInfoOutline } from 'react-icons/md';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import { FieldInputProps } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import ClickOutsideDetector from '../../click-outside-detector/ClickOutsideDetector';
import InputError from '../../input-error/InputError';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';

export interface TextInputLineProps {
  label?: string | MessageDescriptor;
  description?: string | MessageDescriptor;
  placeholder?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  wrapMultipleErrorLines?: boolean;
  onToggle?: (isCollapsed: boolean) => void;
  options?: Array<{ label: string; value: string }>; // Options for dropdown
}

// TODO: Ideally this component should not depend on FieldInputProps (but its rather convenient)
export type Props = TextInputLineProps & Partial<FieldInputProps<string>>;

// Don't use this directly, use the intld version
const TextInputLine: FC<DeIntl<Props>> = (props) => {
  const {
    placeholder,
    label,
    description,
    wrapMultipleErrorLines,
    isTouched = false,
    isDirty = false,
    invalid = false,
    error,
    disabled,
    onChange,
    onBlur,
    name,
    inputRef,
    isValidating,
    value,
    options = [],
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const showSuccess = !invalid && !disabled && isDirty;
  const hasDropdown = options && options.length > 0;

  const fieldClassName = classNames(styles.textInputLineInput, {
    [styles.error]: isTouched && error,
    [styles.success]: showSuccess,
    [styles.pending]: isValidating && isDirty,
    [styles.withDropdown]: hasDropdown,
    [styles.withSuccess]: showSuccess,
  });

  const handleOptionSelect = (selectedValue: string) => {
    if (onChange) {
      // Create a synthetic event that mimics an input change event
      const event = {
        target: {
          value: selectedValue,
          name,
        },
      } as React.ChangeEvent<HTMLInputElement>;

      onChange(event);
    }
    setIsDropdownOpen(false);
  };

  function renderBody() {
    return (
      <div className={styles.inputContainer}>
        <div className={styles.inputWrapper}>
          <input
            data-testid={label}
            type='text'
            className={fieldClassName}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={() => !disabled && hasDropdown && setIsDropdownOpen(true)}
            ref={inputRef}
            name={name}
            value={value || ''}
            aria-invalid={error ? 'true' : 'false'}
          />

          <div className={styles.inputIcons}>
            {showSuccess && (
              <div className={styles.successIcon}>
                <span className='icon-ok' />
              </div>
            )}

            {hasDropdown && (
              <div
                className={styles.dropdownToggle}
                onClick={() => {
                  if (!disabled) {
                    setIsDropdownOpen(!isDropdownOpen);
                  }
                }}
              >
                <FiChevronDown size={24} fontWeight={'bold'} />
              </div>
            )}
          </div>
        </div>

        {isDropdownOpen && hasDropdown && (
          <ClickOutsideDetector onClickOutside={() => setIsDropdownOpen(false)}>
            <div className={styles.dropdownMenu}>
              {options.map((option) => (
                <div
                  key={option.value}
                  className={classNames(styles.dropdownOption, {
                    [styles.selected]: value === option.value,
                  })}
                  onClick={() => handleOptionSelect(option.value)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </ClickOutsideDetector>
        )}
      </div>
    );
  }

  function renderHeader() {
    return (
      <div className={styles.textInputLineHeader}>
        {label && <p className={styles.textInputLineLabel}>{label}</p>}
        {description && (
          <MdInfoOutline
            size={17}
            className={styles.textInputLineDescription}
            title={description}
          />
        )}
        {error && (
          <div
            className={styles.textInputLineError}
            style={{
              whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
            }}
          >
            <InputError touched={isTouched} error={error} />
          </div>
        )}
      </div>
    );
  }

  function renderDefaultElement() {
    return (
      <div className={styles.textInputLine}>
        {renderHeader()}
        {renderBody()}
      </div>
    );
  }

  return renderDefaultElement();
};

export const IntlTextInputLine: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={TextInputLine} props={props} />;
};

export default IntlTextInputLine;
