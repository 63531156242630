import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import classNames from 'classnames';
import { NodeType } from 'common/dist/types/pipeline';
import React, { FC } from 'react';
import { FiAlertOctagon } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

import { PipelineTuningChartErrorType } from '../PipelineTuningChart';

export type NodeData = NodeType & {
  error?: PipelineTuningChartErrorType;
  pipelineIndex?: number;
};
export type FlowElementNode = Node<NodeData, 'node'>;

export const FlowElementNode: FC<NodeProps<FlowElementNode>> = ({ data }) => {
  return (
    <div
      className={classNames(
        'PipelineTuningChart--flow-element-node',
        {
          'PipelineTuningChart--flow-element-node-selected':
            data.selectedNodeId === data.id,
        },
        {
          'PipelineTuningChart--flow-element-node-type-classifier':
            data.nodeType === 'classifier',
        },
        {
          'PipelineTuningChart--flow-element-node-type-transformer':
            data.nodeType === 'transformer',
        }
      )}
    >
      <Handle type={'target'} position={Position.Left} />
      <NodeContent {...data} />
      <Handle type={'source'} position={Position.Right} />
    </div>
  );
};

const nodeTypeLabels = (nodeType: string) => {
  if (nodeType === 'classifier') {
    return {
      id: 'no-id',
      defaultMessage: 'Classifier',
    };
  } else if (nodeType === 'transformer') {
    return {
      id: 'no-id',
      defaultMessage: 'Transformer',
    };
  } else {
    // Fallback
    return {
      id: 'no-id',
      defaultMessage: nodeType,
    };
  }
};

export const NodeContent: FC<NodeProps<FlowElementNode>['data']> = (data) => {
  return (
    <div
      className={
        'PipelineTuningChart--node' +
        (data?.isInactive ? ' PipelineTuningChart--node-inactive' : '')
      }
    >
      {data.error &&
        Object.keys(data.error).some((key) =>
          key.includes(`${data.pipelineIndex}-${data.id}-`)
        ) && (
          <div className={'PipelineTuningChart--node-error-icon'}>
            <FiAlertOctagon size={18} color={'red'} />
          </div>
        )}
      <div data-testid={data?.id} className={'PipelineTuningChart--title'}>
        <span>{data?.displayName}</span>
      </div>
      <div className={'PipelineTuningChart--node-type'}>
        <FormattedMessage {...nodeTypeLabels(data.nodeType)} />
      </div>
    </div>
  );
};
