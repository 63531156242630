import React, { FC, PropsWithChildren } from 'react';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import ExpandCollapseIcon from '../../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import IntlWrapper from '../../../atoms/intl-wrapper/IntlWrapper';
import { DeIntl } from '../../../atoms/intl-wrapper/type';
import GenericCollapsibleContainer from '../generic/GenericCollapsibleContainer';

export type Props = {
  /** Headline of the area */
  title: string | MessageDescriptor;
  /** Is the area initially collapsed? */
  initialCollapsed?: boolean;
  /** Callback for when the expand / collapse toggle is triggered */
  onToggle?: (isCollapsed: boolean) => void;
};

/** Component to render a collapsible area, including a headline and the expand
 * collapse toggle */
const CollapsibleHeadlineArea: FC<DeIntl<PropsWithChildren<Props>>> = (
  props
) => {
  const { initialCollapsed, title, onToggle, children } = props;
  return (
    <GenericCollapsibleContainer
      title={title}
      initialCollapsed={initialCollapsed}
      onToggle={(isCollapsed) => onToggle && onToggle(isCollapsed)}
      renderHeader={(title: string, collapsed: boolean) => (
        <div className={styles.CollapsibleHeadlineAreaHeader}>
          <div className={styles.CollapsibleHeadlineAreaHeaderIcon}>
            <ExpandCollapseIcon isExpanded={!collapsed} onClick={() => {}} />
          </div>
          <span>{title}</span>
        </div>
      )}
      renderBody={() => (
        <div className={styles.CollapsibleHeadlineAreaBody}>{children}</div>
      )}
    />
  );
};

export const IntlCollapsibleHeadlineArea: FC<PropsWithChildren<Props>> = (
  props
) => {
  return (
    <IntlWrapper WrappedComponent={CollapsibleHeadlineArea} props={props} />
  );
};

export default IntlCollapsibleHeadlineArea;
